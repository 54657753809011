import React, { Fragment, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';

import { Btn, H1, H2, H3, H5, H6, P } from '../../../../AbstractElements';
import TopHeader from '../TopHeader';
import Footer from '../Footer';

import landing_bg from '../../../../assets/images/landing/landing_top_banner.jpg';

import '../index.css';
import { HelpCircle } from 'react-feather';
import { Fade } from 'react-reveal';

const FAQPage = () => {

    const sessionData = [
        {
            title: "What is eVibeChecks?",
            text: "eVibeChecks is an innovative HR survey platform designed to streamline the process of gathering and analyzing employee feedback. Our tool offers customizable surveys, insightful analytics, and user-friendly interfaces to support your HR decision-making."
        },
        {
            title: "How does eVibeChecks work?",
            text: "It's simple! Select the type of survey you need, distribute it among your employees, and then analyze the results using our intuitive dashboard. Our platform is designed to make the survey process efficient and insightful."
        },
        {
            title: "Is my data safe with eVibeChecks?",
            text: "Absolutely. At eVibeChecks, data security is a top priority. We use advanced encryption and security protocols to ensure that all survey data and personal information are protected."
        },
        {
            title: "What sets eVibeChecks apart from other survey platforms?",
            text: "eVibeChecks stands out for its ease of use, customizable survey options, and powerful analytics. We focus on providing HR teams with the tools they need to gain meaningful insights into employee feedback."
        },
        {
            title: "Can I customize surveys to fit my organization's needs?",
            text: "Yes, our platform allows you to customize surveys to meet the specific needs of your organization. You can choose from a variety of question types, themes, and settings to create surveys that are relevant to your HR objectives."
        },
        {
            title: "Are there any fees associated with using eVibeChecks?",
            text: "We offer various pricing plans to suit different organizational needs. There are no hidden fees, and our pricing structure is transparent and straightforward."
        },
        {
            title: "How do I contact eVibeChecks customer support?",
            text: "Our customer support team is readily available to assist you. You can contact us through the 'Contact Us' section on our platform, where you'll find options for email, live chat, and phone support."
        },
        {
            title: "Can I integrate eVibeChecks with other HR tools?",
            text: "Yes, eVibeChecks is designed to integrate seamlessly with various HR tools and systems, enhancing your HR workflow and data management."
        },
        {
            title: "Can I export survey data for further analysis?",
            text: "Yes, you can easily export survey data from our platform for further analysis or reporting purposes. We support multiple export formats for your convenience."
        },
        {
            title: "Can I delete my account?",
            text: "Yes. If you need to delete your account, please contact our support team for assistance. Note that this action is irreversible and will result in the loss of all stored data."
        },
    ]


    const [isActivity, setIsActivity] = useState([]);
    const handelChange = (e) => {
        let collapseCopy = [...isActivity];
        collapseCopy[e] = !collapseCopy[e];
        setIsActivity(collapseCopy);
    };

    return (
        <div className="landing-page">
            <TopHeader noHome/>
            <div style={{ marginTop: 100 }}>
                <H2 attrH2={{ className: 'm-4 font40-bold font-outfit text-dark' }} >{'Frequently Asked Questions (FAQs) - Mothrift'}</H2>
            </div>

            <Row className='about-section' style={{ margin: '40px' }}>
                <Col xl='8' lg='6' md='7' className='box-col-8 xl-60'>
                    {sessionData && sessionData.map((item, i) => {
                        return (
                            <Fade right>
                                <Card className='font-outfit pt-4 pb-4' key={i} onClick={() => handelChange(i)} style={{ borderRadius: 8 }}>
                                    <CardHeader className='pb-0 mb-0 pt-0' style={{ borderBottomWidth: 0 }}>
                                        <div className='d-flex' style={{ alignItems: 'center', gap: 15 }}>
                                            <HelpCircle size={28} />
                                            <H3 attrH3={{ className: 'mb-0 font-bold text-grey font-outfit' }}>{item.title}</H3>
                                        </div>
                                    </CardHeader>
                                    <Collapse isOpen={isActivity[i]}>
                                        <CardBody>
                                            <H5 attrH5={{ className: 'font-outfit' }}>{item.text}</H5>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Fade>
                        );
                    })}
                </Col>
                <H3 attrH3={{ className: 'font-outfit' }}>{'These FAQs aim to provide clarity on common questions users may have about MoThrift, ensuring a transparent and user-friendly experience.'}</H3>
            </Row>

            <Footer />

        </div >
    );
};

export default FAQPage;
