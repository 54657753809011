import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import _ from 'lodash';
import TotalUser from './TotalUser';
import TotalValueWidget from './TotalValueWidget';
import { getBalanceInfoAPI, getSurveyAllDetailsAPI, getTotalUserInfoAPI, getTotalWalletInfoAPI, verifyAdminTokenAPI } from '../../../Library/apis';
import BalanceInfoWidget from './BalanceInfoWidget';
import UserContext from '../../../_helper/User';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { logOut } from '../../../Library/api_utils';
import Charts from './Chart';

const Home = () => {
  const { userInfo } = useContext(UserContext);

  const [isBalanceLoading, setIsBalanceLoading] = useState(false);

  const [totalValues, setTotalValues] = useState({
    totalHappyNumber: 0,
    totalInviteNumber: 0,
    totalResponseNumber: 0,
    totalResponseRate: 0,
    totalEmotionCounts: {},
    totalReasonCounts: {},
    emotionDepartmentAnalysis: {}
  });

  const navigate = useNavigate();

  useEffect(() => {
    verifyAdminTokenAPI().then().catch((e) => {
      toast.warning("Your session is expired, please login again");
      logOut();
      navigate('/login');
    });
  }, []);

  useEffect(() => {
    getSurveyAllDetailsAPI().then(res => {
      console.log(res);
      setTotalValues(res.body?.analysis);
    }).catch((e) => {
      toast.error("Error while getting survey details");
    });
  }, []);

  const memoizedHome = useMemo(() => (
    <Fragment>
      <Breadcrumbs mainTitle='Dashboard' />
      <Container fluid={true}>
        <Row className='widget-grid font-outfit'>
          {userInfo.roles && userInfo.roles.includes('View Total Values') && <TotalValueWidget data={totalValues} isLoading={isBalanceLoading} />}
          <Charts data={totalValues} />
        </Row>
      </Container>
    </Fragment>
  ), [userInfo, totalValues, isBalanceLoading]);

  return memoizedHome;
};

export default Home;
