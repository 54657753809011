import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
import _ from 'lodash';

import { Breadcrumbs } from '../../../../AbstractElements';
import PositionTable from './PositionTable';
import './index.css';

const StaffPositons = () => {

    return (
        <Fragment>
            <Breadcrumbs mainTitle='All Staff Positions'/>
            <Container fluid={true}>
                <PositionTable />
            </Container>
        </Fragment >
    );
};

export default StaffPositons;
