import { apiDelete, apiGet, apiPost, apiPut } from '../api_utils';
import { API_URL, Payment_API_URL, SOCKET_URL } from '../constant';


// Admin Api
export const createAdminAPI = data => apiPut(`${API_URL}/AdminUser/CreateAdmin`, data);

export const adminLoginAPI = data => apiPut(`${API_URL}/AdminUser/LogIn`, data);

export const getAdminUserAPI = () => apiGet(`${API_URL}/AdminUser/GetAdminUser`);

export const getAllStaffsAPI = data => apiGet(`${API_URL}/AdminUser/GetAllStaffs`, data);

export const getAllCustomersAPI = () => apiGet(`${API_URL}/AdminUser/GetAllCustomers`);

export const getTotalWalletInfoAPI = () => apiGet(`${API_URL}/AdminUser/GetTotalWalletInfo`);

export const getTotalUserInfoAPI = () => apiGet(`${API_URL}/AdminUser/GetTotalUserInfo`);

export const getTotalEarnHistoryAPI = () => apiGet(`${API_URL}/AdminUser/GetTotalEarnHistory`);

export const getAllPaymentHistoryAPI = () => apiGet(`${API_URL}/AdminUser/GetAllPaymentHistory`);

export const getTotalAvailableTermsAPI = () => apiGet(`${API_URL}/AdminUser/GetTotalAvailableTerms`);

export const getRoleListsAPI = () => apiGet(`${API_URL}/AdminUser/GetRoleLists`);

export const getStaffRolesAPI = () => apiGet(`${API_URL}/AdminUser/GetStaffRoles`);
export const getDepartmentsAPI = () => apiGet(`${API_URL}/AdminUser/GetDepartments`);
export const getEmptyDepartmentsAPI = () => apiGet(`${API_URL}/AdminUser/GetEmptyDepartments`);

export const createStaffRoleAPI = data => apiPost(`${API_URL}/AdminUser/CreateStaffRole`, data);
export const updateStaffRoleAPI = data => apiPost(`${API_URL}/AdminUser/UpdateStaffRole`, data);
export const deleteStaffRoleAPI = data => apiPost(`${API_URL}/AdminUser/DeleteStaffRole`, data);

export const createDepartmentAPI = data => apiPost(`${API_URL}/AdminUser/CreateDepartment`, data);
export const updateDepartmentAPI = data => apiPost(`${API_URL}/AdminUser/UpdateDepartment`, data);
export const deleteDepartmentAPI = data => apiPost(`${API_URL}/AdminUser/DeleteDepartment`, data);

export const addStaffAPI = data => apiPut(`${API_URL}/AdminUser/AddStaff`, data);

export const updateStaffAPI = data => apiPut(`${API_URL}/AdminUser/UpdateStaff`, data);

export const updateProfileAPI = data => apiPut(`${API_URL}/AdminUser/UpdateProfile`, data);

export const disableStaffAPI = data => apiPost(`${API_URL}/AdminUser/DisableStaff`, data);

export const deleteStaffAPI = data => apiPost(`${API_URL}/AdminUser/DeleteStaff`, data);

export const changeAdminPasswordAPI = data => apiPut(`${API_URL}/AdminUser/ChangePassword`, data);

export const resetAdminPasswordAPI = data => apiPut(`${API_URL}/AdminUser/ResetPassword`, data);

export const confirmOTPCodeAPI = data => apiPut(`${API_URL}/AdminUser/ConfirmOTPCode`, data);

export const setPasswordAPI = data => apiPut(`${API_URL}/AdminUser/SetPassword`, data);

export const getCustomerNotificationsAPI = () => apiGet(`${API_URL}/AdminUser/GetCustomerNotifications`);

export const getAdminNotificationsAPI = () => apiGet(`${API_URL}/AdminUser/GetAdminNotifications`);

export const setBalanceInfoAPI = data => apiPost(`${API_URL}/AdminUser/SetBalanceInfo`, data);

export const getBalanceInfoAPI = () => apiGet(`${API_URL}/AdminUser/GetBalanceInfo`);

export const disableUserAPI = data => apiPost(`${API_URL}/AdminUser/DisableUser`, data);

export const verifyAdminTokenAPI = () => apiPut(`${API_URL}/AdminUser/VerifyAdminToken`);


// User Api
export const signUpAPI = data => apiPost(`${API_URL}/CustomerUser/Auth/SignUp`, data);

export const loginAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/LogIn`, data);

export const activeUserAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/ActivateUser`, data);

export const changePasswordAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/ChangePassword`, data);

export const resetPasswordAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/ResetPassword`, data);

export const getUserAPI = () => apiGet(`${API_URL}/CustomerUser/Auth/GetUser`);

export const updateUserAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/UpdateUser`, data);

export const updateSocketIdAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/UpdateSocketId`, data);

export const uploadProfileImageAPI = data => apiPost(`${SOCKET_URL}/upload`, data);

export const deleteAccountAPI = () => apiPut(`${API_URL}/CustomerUser/Auth/DeleteAccount`);

export const sendVerificationRequestAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/SendVerificationRequest`, data);

export const confirmResetPasswordAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/ConfirmResetPassword`, data);

export const set2FAAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/Set2FA`, data);

export const get2FAAPI = () => apiGet(`${API_URL}/CustomerUser/Auth/Get2FA`);

// Savings
export const getWalletInfoAPI = () => apiGet(`${API_URL}/CustomerUser/Savings/GetWalletInfo`);

export const getBalancesAPI = () => apiGet(`${API_URL}/CustomerUser/Savings/GetBalances`);

export const setFixAPI = data => apiPut(`${API_URL}/CustomerUser/Savings/SetFix`, data);

export const cancelFixAPI = data => apiPut(`${API_URL}/CustomerUser/Savings/CancelFix`, data);

export const getTermFixesAPI = () => apiGet(`${API_URL}/CustomerUser/Savings/GetTermFixs`);

export const getUserAvailableTermsAPI = () => apiGet(`${API_URL}/CustomerUser/Savings/GetUserAvailableTerms`);

export const getUserNotificationsAPI = () => apiGet(`${API_URL}/CustomerUser/Savings/GetUserNotifications`);

export const getPaymentHistoryAPI = () => apiGet(`${API_URL}/CustomerUser/Savings/GetPaymentHistory`);

export const getEarnHistoryAPI = () => apiGet(`${API_URL}/CustomerUser/Savings/GetEarnHistory`);

export const transferMoneyToSavingsAPI = data => apiPut(`${API_URL}/CustomerUser/Savings/TransferMoneyToSavings`, data);


// Survey
export const createSurveyAPI = () => apiPost(`${API_URL}/Survey/create_survey`);
export const sendSurveyAPI = data => apiPost(`${API_URL}/Survey/send_survey`, data);
export const submitSurveyAPI = data => apiPost(`${API_URL}/Survey/submit_survey`, data);
export const getSurveyListAPI = () => apiGet(`${API_URL}/Survey/get_survey_list`);
export const getSurveyAPI = data => apiPost(`${API_URL}/Survey/get_survey`, data);
export const checkSurveyAPI = data => apiPost(`${API_URL}/Survey/check_survey`, data);

export const setTempEmployeeListAPI = data => apiPost(`${API_URL}/Survey/SetTempEmployeeList`, data);
export const getTempEmployeeListAPI = () => apiGet(`${API_URL}/Survey/GetTempEmployeeList`);

export const getSurveyAllDetailsAPI = () => apiGet(`${API_URL}/Survey/GetSurveyAllDetails`);

// Payments
export const notifyEnquityAPI = data => apiPost(`${Payment_API_URL}/name_enquiry`, data);

export const notifyPaymentAPI = data => apiPost(`${Payment_API_URL}/notify_payment`, data);
