import React, { useState, useContext } from "react";
import { Row, Col, Modal, ModalHeader, Form, FormGroup, Label, Input, Spinner } from 'reactstrap'
import { toast } from "react-toastify";
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { Btn, H4 } from '../../../../AbstractElements';
import { addStaffAPI } from "../../../../Library/apis";
import UserContext from "../../../../_helper/User";

const animatedComponents = makeAnimated();

const AddStaffsModal = ({ modal, toggle, updateStaffList }) => {

    const { emptyDepartments, updateDepartments } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        departments: [],
        type: "Manager"
    });

    const onEditSubmit = (data) => {
        setIsLoading(true);
        addStaffAPI({ ...formValues, departmentIds: formValues.departments.map(item => item.value) }).then(res => {
            toast.success(res.message);
            updateStaffList();
            updateDepartments();
            toggle();
            setIsLoading(false);
        }).catch(e => {
            toast.error(e?.response?.data?.message || 'Add Manager Error', { autoClose: 1000 });
            setIsLoading(false);
        });
    }
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormValues(({ ...formValues, [name]: value }));
    };

    const handleSelectChange = (selectedOptions) => {
        setFormValues(prevValues => ({ ...prevValues, departments: selectedOptions }));
    };

    return (
        <Modal isOpen={modal} toggle={toggle} centered size={'lg'} className="font-outfit">
            <ModalHeader toggle={toggle} >
                <H4>{'Add Company Manager'}</H4>
            </ModalHeader>
            <div className='d-flex flex-direction-column' style={{ flexDirection: 'column', gap: 20, marginLeft: 30, marginRight: 30, marginBottom: 30 }}>
                <Form>
                    <Row>
                        <Col md="4">
                            <FormGroup className="mb-3">
                                <Label className="form-label">{'First Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="First Name"
                                    name="firstName"
                                    required
                                    value={formValues.firstName || ""}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup> <Label className="form-label">{'Last Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    value={formValues.lastName || ""}
                                    name="lastName"
                                    onChange={handleChange}
                                    placeholder="Last Name"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup><Label className="form-label">{'Email Address'}</Label>
                                <Input
                                    className="form-control"
                                    type="email"
                                    value={formValues.email || ""}
                                    onChange={handleChange}
                                    name="email"
                                    placeholder="Email Address"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup><Label className="form-label">{'Phone Number'}</Label>
                                <Input
                                    className="form-control"
                                    type="phone"
                                    value={formValues.phoneNumber || ""}
                                    onChange={handleChange}
                                    name="phoneNumber"
                                    placeholder="Phone Number"
                                    required />
                            </FormGroup>
                        </Col>
                        <Col sm="12" md="8">
                            <FormGroup><Label className="form-label">{'Departments'}</Label>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    options={emptyDepartments}
                                    className="js-example-basic-single col-sm-12"
                                    isMulti
                                    value={formValues?.departments}
                                    onChange={handleSelectChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <Btn attrBtn={{ color: 'primary', onClick: onEditSubmit, className: 'w-100 font18', style: { height: 40 } }}>
                    {isLoading && <Spinner size="sm" color="light" className='me-2' />}
                    {isLoading ? 'Creating' : '+ Create'}
                </Btn>
            </div>
        </Modal >
    );
};

export default AddStaffsModal;
