import React, { Fragment, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import H3 from '../Headings/H3Element';

const Breadcrumbs = (props) => {

  return (
    <Fragment>
      <Container fluid={true}>
        <div className='page-title p-4'>
          <Row>
            <Col xs='6'>
              <H3 attrH3={{ style: { fontFamily: 'Outfit', fontWeight: 600, fontSize: 20 } }}>{props.mainTitle}</H3>
            </Col>
            {props?.showRightbar && <Col xs='6'>
              <ol className='breadcrumb'>
                <li onClick={props?.onClickRightBar} className='breadcrumb-item active text-warning'>{props.title}</li>
              </ol>
            </Col>}
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
