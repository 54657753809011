import React, { useState, useEffect } from 'react';
import './index.css';
import { Button, Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { useNavigate } from 'react-router';
import { Image } from '../../../AbstractElements';
import landing_bg from '../../../assets/images/brand/logo_yellow.png';

const TopHeader = ({ noHome }) => {

    const [isScrolling, setIsScrolling] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);

    const navigate = useNavigate();
    const handleNavigate = (path) => navigate(path);

    const handleCreateAccount = () => {
        handleNavigate('/survey/auth/sign-up');
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        const maxScrollHeight = document.documentElement.scrollHeight - window.innerHeight;

        setPrevScrollPos(prev => {
            if (Math.abs(currentScrollPos - prev) < 20 || currentScrollPos > maxScrollHeight || currentScrollPos < 0) return prev;
            setIsScrolling(currentScrollPos > prev);
            return currentScrollPos;
        });
    };

    return (
        <div className={`font-outfit landing-top-banner ${noHome ? 'bg' : ''} ${isScrolling ? 'scroll' : ''}`}>
            <Navbar light expand="md" style={{ width: '100%' }}>
                <NavbarBrand className="font32_outfit text-light p-t-10 p-l-20" href="/">
                    <Image attrImage={{ className: 'p-0', src: landing_bg, alt: 'shield', style: { width: 200 } }} />
                </NavbarBrand>
                <Nav className="ml-auto w-100 " navbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className='font16_outfit mt-0 nav-container'>
                        <NavItem>
                            <NavLink className='text-light font-outfit' href="#home" onClick={() => { navigate('/home') }} >Home</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className='text-light font-outfit' href="#savings" onClick={() => { navigate('/home') }}>Surveys</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className='text-light font-outfit' href="#features" onClick={() => { navigate('/home') }}>Features</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className='text-light font-outfit' href="#pricing" onClick={() => { navigate('/pricing') }}>pricing</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className='text-light font-outfit' href="#faqs" onClick={() => { navigate('/faqs') }}>FAQs</NavLink>
                        </NavItem>
                    </div>

                    <div className='font16_outfit mt-0 register-container'>
                        <NavItem>
                            <NavLink className='text-light mt-1 font-outfit' href="/login" onClick={(e) => {
                                e.preventDefault();
                                handleNavigate('/login');
                            }}>Log In</NavLink>
                        </NavItem>
                        <Button className="font16_outfit signup-btn" color="secondary" onClick={handleCreateAccount}>{'Sign Up - FREE'}</Button>
                    </div>

                </Nav>
            </Navbar>
        </div >
    );
};

export default TopHeader;
