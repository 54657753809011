import React, { Fragment, useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Badges, Btn, H3, H4, H5, H6 } from '../../../../AbstractElements';
import { getAllStaffsAPI } from '../../../../Library/apis';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Delete, Edit, Eye } from 'react-feather';
import AddStaffsModal from './AddStaffsModal';
import EditStaffsModal from './EditStaffModal';
import ViewStaffsModal from './ViewStaffModal';
import UserContext from '../../../../_helper/User';
import SubHeader from '../../Common/SubHeader';
import SurveyLoader from '../../Common/SurveyLoader';

const tableColumns = [
    {
        name: 'Name',
        selector: row => row['name'],
        sortable: true,
        center: false,
    },
    {
        name: 'Phone Number',
        selector: row => row['phone'],
        sortable: false,
        center: true,
    },
    {
        name: 'Email',
        selector: row => row['email'],
        sortable: false,
        center: false,
    },
    {
        name: 'Disabled',
        selector: row => row['disabled'],
        sortable: false,
        center: true,
    },
    {
        name: 'Action',
        selector: row => row['action'],
        center: true,
    },
];

const StaffTable = () => {

    const [staffs, setStaffs] = useState([]);

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [showEditModal, setEditModal] = useState(false);
    const [showViewModal, setViewModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedStaff, setSelectedStaff] = useState();
    const [selectedViewStaff, setSelectedViewStaff] = useState();

    const { userInfo } = useContext(UserContext);
    const isHasEditStaffRole = userInfo.roles && userInfo.roles.includes("Add/Update Staff");

    useEffect(() => {
        if (selectedStaff) setEditModal(true);
    }, [selectedStaff])

    useEffect(() => {
        if (selectedViewStaff) setViewModal(true);
    }, [selectedViewStaff])

    useEffect(() => {
        updateStaffList();
    }, [])

    const updateStaffList = () => {
        setIsLoading(true);
        getAllStaffsAPI({ type: "Admin" }).then(res => {
            setIsLoading(false);
            setStaffs(res.staffs);
        }).catch(() => {
            setIsLoading(false);
        });
    }

    const renderItem = (item, handleEdit, handleView) => {

        const isDisabled = !item.active;
        const color = isDisabled ? 'warning' : 'success';

        return {
            name: <H6 attrH6={{ className: 'ms-2 mt-2' }}>{item?.userName || (item?.userInfo?.firstName + ' ' + item?.userInfo?.lastName)}</H6>,
            phone: <H6 attrH6={{ className: 'text-black-50' }}>{item.phoneNumber || 'Phone not available'}</H6>,
            email: <H6 attrH6={{ className: 'text-muted' }}>{item.email || ''}</H6>,
            disabled: <Badges attrBadge={{ className: 'badge rounded-pill font14', color: color, pill: true }}>{isDisabled ? 'disabled' : 'active'}</Badges>,
            action:
                <div style={{ display: 'flex', gap: 10 }}>
                    {isHasEditStaffRole && <div onClick={handleEdit} style={{ cursor: 'pointer' }} className='me-2'>
                        <Edit color={'#4D4D4D'} size={16} />
                    </div>}
                    <div onClick={handleView} style={{ cursor: 'pointer' }} className='me-2'>
                        <Eye color={'#4D4D4D'} size={16} />
                    </div>
                </div>
        }
    }

    return (
        <>
            <SubHeader title="All HR Admins" data={staffs} />
            <Card className='tranaction-card' style={{ height: '60vh' }}>
                <CardBody className='pt-4'>
                    <Btn attrBtn={{ color: 'primary', onClick: () => { toggle(); }, className: 'mb-3 font16', style: { height: 40 } }}>{'+ Add HR Admin'}</Btn>
                    <div className={`show table-responsive recent-table transaction-table`}>
                        {isLoading ? <SurveyLoader /> :
                            <DataTable
                                data={staffs.map((item, i) =>
                                    renderItem(item, () => { setSelectedStaff(item) }, () => { setSelectedViewStaff(item) }))}
                                columns={tableColumns}
                                striped
                                center
                            />}
                    </div>
                </CardBody>
            </Card>

            <AddStaffsModal
                modal={modal}
                toggle={toggle}
                updateStaffList={updateStaffList}
            />

            <EditStaffsModal
                modal={showEditModal}
                toggle={() => {
                    setEditModal(false);
                    setSelectedStaff(null);
                }}
                item={selectedStaff}
                updateStaffList={updateStaffList}
            />

            <ViewStaffsModal
                modal={showViewModal}
                toggle={() => {
                    setViewModal(false);
                    setSelectedViewStaff(null);
                }}
                item={selectedViewStaff}
            />
        </>
    )
}
export default StaffTable