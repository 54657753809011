import React, { useContext } from 'react';
import { Card, CardBody, Col, Input, Row } from 'reactstrap';

import LineChartClass from './LineChart';
import RadialChart from './RadialChart';
import UserContext from '../../../../_helper/User';
import { H3, P } from '../../../../AbstractElements';
import { CONSTANTS } from './../../../../Library/theme';

const Charts = () => {

  const { departments } = useContext(UserContext);
  return (
    <Row>
      <H3>{'Employee Net Promoter Score (eNPS)'}</H3>
      <P>{'This score is calculated by measuring the answers from a Pulse Survey Question.'}</P>
      <Col xs={12}>
        <div className="mb-3">
          <div className='d-flex gap-4'>
            <Input className="btn-square rounded" name="select" type="select">
              {departments.map(item => <option key={item._id} value="opt2">{item.name}</option>)}
            </Input>
            <Input className="btn-square rounded" name="select" type="select">
              {CONSTANTS.reactions.map(item => <option key={item} value="opt2">{item}</option>)}
            </Input>
          </div>
        </div>
      </Col>
      <Card>
        <CardBody className="chart-block">
          <Row>
            <Col xs={4}>
              <RadialChart value={0} />
            </Col>
            <Col xs={1} />
            <Col xs={7}>
              <LineChartClass />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Row>
  );
};

export default Charts;