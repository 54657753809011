import React from 'react';
import { Row } from 'reactstrap';
import Fade from 'react-reveal/Fade';

import { H1 } from '../../../../AbstractElements';
import TopHeader from '../TopHeader';
import Footer from '../Footer';

import landing_bg from '../../../../assets/images/landing/landing_top_banner.jpg';

import '../index.css';

const About = () => {

    const sessionData = [
        {
            title: "ABOUT US",
            texts: [
                "Welcome to eVibeChecks – Your Partner in HR Excellence!",
                "At eVibeChecks, we are dedicated to empowering organizations with insightful HR data. We understand the importance of employee feedback in shaping a thriving workplace, and that's why we've developed a comprehensive survey platform tailored for your HR needs."
            ],
        },
        {
            title: "Who We Are:",
            texts: [
                "eVibeChecks is more than just a survey platform; we are your strategic HR partner. Founded with the vision of transforming employee feedback into actionable insights, we believe in the power of data-driven, secure, and user-friendly HR solutions."
            ],
        },
        {
            title: "What Sets Us Apart:",
            subTitle: "Innovative HR Survey Solutions",
            texts: [
                "Navigating HR challenges requires more than just data collection. eVibeChecks offers innovative survey tools that simplify gathering and analyzing employee feedback, helping you make informed HR decisions."
            ],
        },
        {
            subTitle: "Customizable and User-Friendly",
            texts: [
                "Every organization is unique, and so are its HR needs. Our platform offers customizable surveys to suit your specific requirements, ensuring a seamless experience for both HR professionals and employees."
            ],
        },
        {
            subTitle: "Data-Driven Insights",
            texts: [
                "We turn survey responses into meaningful insights. With our advanced analytics, you can understand employee sentiment, identify areas for improvement, and track the effectiveness of your HR initiatives."
            ],
        },
        {
            subTitle: "How It Works",
            texts: [
                "Engaging with eVibeChecks is straightforward. Choose your survey type, distribute it among your employees, and receive comprehensive reports and analytics. Our intuitive interface makes managing HR surveys effortless."
            ],
        },
        {
            subTitle: "Join Us on Your HR Journey",
            texts: [
                "Whether you're looking to enhance employee engagement, streamline performance evaluations, or gather actionable feedback, eVibeChecks is here to support you. Embark on this transformative HR journey with us and unlock the full potential of your workforce.",
                "Partner with eVibeChecks – Empower Your HR Strategy."
            ],
        },
    ]
    

    return (
        <div className="landing-page">
            <div className='first-section' style={{ backgroundImage: `url(${landing_bg})`, height: 120 }}>
                <TopHeader />
            </div>

            {sessionData.map(item => <Fade right>
                <Row className="about-section" style={{ margin: '40px' }}>
                    {item.title && <H1 attrH1={{ className: 'font40-bold font-outfit text-dark', style: { marginTop: '20px' } }}>{item.title}</H1>}
                    {item.subTitle && <H1 attrH1={{ className: 'font32-bold font-outfit text-dark', style: { marginTop: '20px' } }}>{item.subTitle}</H1>}
                    {item.texts.map(text=><H1 attrH1={{ className: 'font24_outfit text-dark', style: {} }}>{text}</H1>)}
                </Row>
            </Fade>)}

            <Footer />

        </div >
    );
};

export default About;
