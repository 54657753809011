import React, { Fragment, useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, Col } from 'reactstrap';
import HeaderCard from '../../../Common/Component/HeaderCard';
import UserContext from '../../../../_helper/User';
import { COLORS } from '../../../../Library/theme';

const StackBar = ({ data }) => {
    const { departments } = useContext(UserContext);

    const getSeriesData = (index) => {
        const result = departments.map((department) => {
            return data[department.name]?.emotions[4 - index] || 0;
        });
        console.log(result);
        return result;
    };

    function generateChartData() {
        const categories = ['Happy', 'Smiley', 'Indifferent', 'Worried', 'Angry'];
        return categories.map((category, index) => ({
            name: category,
            data: getSeriesData(index)
        }));
    }

    const [chartData, setChartData] = useState({
        series: generateChartData(),
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                stackType: '100%',
                toolbar: {
                    show: false
                },
            },
            colors: COLORS.chartColors,
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            xaxis: {
                categories: departments.map(department => department.name),
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + "K"
                    }
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            }
        }
    });

    useEffect(() => {
        if (departments && data) {
            setChartData({
                series: generateChartData(),
                ...chartData.options
            })
        }
    }, [departments, data])

    return (
        <Fragment>
            <Col sm='12' xl='6'>
                <Card>
                    <HeaderCard title={'Vibe Check By Department'} />
                    <CardBody>
                        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
}

export default StackBar;