import React from 'react';
import './index.css';
import { useNavigate } from 'react-router';
import { Col, Row } from 'reactstrap';
import { H1, Image } from '../../../AbstractElements';
import { IMAGES } from '../../../Library/theme';

const Footer = () => {

    const navigate = useNavigate();
    const handleNavigate = (path) => navigate(path);

    const SectionData = {
        SessionFooter: {
            address: {
                title: 'MoThrift',
                data: [
                    '5 Bathurst Street',
                    'Wuse 2',
                    'Abuja, FCT',
                ]
            },
            parts: [
                {
                    title: 'Our Company',
                    data: [
                        {
                            title: 'About Us',
                            route: 'about'
                        },
                        {
                            title: 'FAQs',
                            route: 'faq'
                        },
                    ]
                },
                {
                    title: 'Products',
                    data: [
                        {
                            title: 'Savings Plan',
                            route: 'savings-plan'
                        },
                    ]
                },
                {
                    title: 'Legal',
                    data: [
                        {
                            title: 'Privacy',
                            route: 'privacy-policy'
                        },
                        {
                            title: 'Terms',
                            route: 'terms'
                        },
                    ]
                },
                {
                    title: 'Contact US',
                    data: [
                        {
                            title: 'WhatsApp: 0903 669 1857',
                            link: `https://wa.me/09036691857`
                        }
                    ]
                },
            ]
        }
    }

    return (
        <>
            {/* Footer */}
            < Row className="landing_footer" >
                <Col xxl={4} xl={4}>
                    <div style={{ margin: '0px 0 0 0px', zIndex: 1, cursor: 'pointer' }} onClick={() => {
                        window.scrollTo(0, 0);
                        navigate('/home');
                    }}>
                        <Image attrImage={{ className: 'p-0', src: IMAGES.logo_yellow, alt: 'shield', style: { width: 200 } }} />
                    </div>
                    {/* <H1 attrH1={{ className: 'font40_outfit text-dark' }}>{SectionData.SessionFooter.address.title}</H1> */}
                    <div style={{ gap: 5, display: 'flex', flexDirection: 'column' }}>
                        {SectionData.SessionFooter.address.data.map((text, i) => <div className={`font16_outfit text-gray ${i == 0 ? 'm-t-20' : ''}`} style={{ fontWeight: 400 }}>{text}</div>)}
                    </div>
                </Col>
                <Col xxl={6} xl={8} xs={12}>
                    <Row>
                        {SectionData.SessionFooter.parts.map(item => <Col xs={3}>
                            <H1 attrH1={{ className: 'font24_outfit text-dark text-bold' }}>{item.title}</H1>
                            <div style={{ gap: 10, display: 'flex', flexDirection: 'column' }}>
                                {item.data.map((element, i) => <div
                                    onClick={() => {
                                        element?.route && navigate(`/${element.route}`);
                                        element?.link && window.open(element?.link, '_blank');
                                        window.scrollTo(0, 0);
                                    }}
                                    className={`font16_outfit text-gray ${i == 0 ? 'm-t-20' : ''}`}
                                    style={{ fontWeight: 400, cursor: 'pointer' }}
                                >
                                    {element.title}
                                </div>)}
                            </div>
                        </Col>)}
                    </Row>
                </Col>
                <Col xs={12}>
                    <div style={{ background: '#00000017', height: '1px', marginTop: '120px' }}></div>
                    <div className='font16_outfit text-dark mt-2' ><i className="fa fa-regular fa-copyright fa-xl me-1" ></i>{`${new Date().getFullYear()} HR Survey BackOffice. All rights reserved`}</div>
                </Col>
            </Row >
        </>
    );
};

export default Footer;
