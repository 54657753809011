import React, { useState } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import TransactionTable from './TransactionTable';
import SubHeader from '../../Common/SubHeader';
import FilterHeader from '../../Common/FilterHeader';
import { useEffect } from 'react';

const Transactions = ({ data: defaultData, isLoading }) => {

  const [data, setData] = useState(defaultData);

  useEffect(() => { setData(defaultData) }, [defaultData])

  const filterOptions = [
    { label: 'User ID', value: 'userId' },
    { label: 'Amount', value: 'amount' },
    { label: 'Bank Name', value: 'bankName' },
    { label: 'Bank Account', value: 'bankAccount' },
  ]

  return (
    <>
      <SubHeader title="Bank Transfer" data={data} />
      {/* <FilterHeader title="Search" data={defaultData} setData={setData} filterOptions={filterOptions} /> */}
      <Card className='tranaction-card'>
        <CardBody className='pt-4'>
          <div className={`table-responsive recent-table transaction-table`}>
            <TransactionTable active={'Deposit'} data={data.filter(item => item.type == 'Withraw')} isLoading={isLoading} />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Transactions;
