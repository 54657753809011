import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Breadcrumbs, H1, H3, H5 } from '../../../AbstractElements';
import _ from 'lodash';
import { Container, Row, Card, CardBody, Col, Button, Toast } from 'reactstrap';

import ExcelReader from './ExelReader';
import { createSurveyAPI } from '../../../Library/apis';
import { Copy } from 'react-feather';
import './index.css';
import { toast } from 'react-toastify';

const SurveyManagement = () => {

    const [surveyLink, setSurveyLink] = useState('');

    const handleCreate = () => {
        createSurveyAPI().then((res) => {
            setSurveyLink(res.body);
        })
    }

    const handleCopyLink = () => {
        if (!surveyLink) {
            toast.info('No link to copy', { autoClose: 1000 });
            return;
        }

        navigator.clipboard.writeText(surveyLink)
            .then(() => {
                toast.success('Link copied to clipboard', { autoClose: 1000 });
            })
            .catch(err => {
                toast.error('Failed to copy link: ', { autoClose: 1000 });
            });
    }

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Survey Management' />
            <Container fluid={true}>
                <H3 attrH3={{ className: 'font-outfit text-muted' }}>{'1. Generate Survey Link Manually'}</H3>
                <Card className='tranaction-card p-3'>
                    <CardBody className='pt-2 ps-2 pb-2'>
                        <div className='d-flex align-items-center' style={{ justifyContent: 'space-between' }}>
                            <Button className='me-2' color='primary' onClick={handleCreate}>{'+ Generate'}</Button>
                            {surveyLink && (
                                <>
                                    <div className='font-outfit font18' style={{ maxWidth: '80%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>
                                        {surveyLink}
                                    </div>
                                    <div className='icon-button survey-button' onClick={handleCopyLink}>
                                        <Copy color={'#ffffff'} size={15} />
                                    </div>
                                </>
                            )}
                        </div>

                    </CardBody>
                </Card>
                <H3 attrH3={{ className: 'font-outfit text-muted' }}>{'2. Send Survey to imported employeers'}</H3>
                <Card className='tranaction-card p-3'>
                    <CardBody className='pt-2 ps-2 pb-2'>
                        <ExcelReader />
                    </CardBody>
                </Card>
            </Container >
        </Fragment >
    );
};

export default SurveyManagement;
