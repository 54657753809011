import Context from './index';
import React, { useEffect, useState } from 'react';

const JobSearchProvider = (props) => {
    const [jobData, setJobData] = useState([]);
    const [searchData, setSearchData] = useState([]);

    return (
        <Context.Provider
            value={{
                ...props,
                jobData,
                searchData
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default JobSearchProvider;