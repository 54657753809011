import React, { useState } from 'react';
import { Row, Col, Card, CardHeader, Collapse, CardBody } from 'reactstrap';
import { TypeAnimation } from 'react-type-animation';
import { useNavigate } from 'react-router';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

import { Btn, H1, H2, H3, H5, Image, P } from '../../../AbstractElements';

import TopHeader from './TopHeader';
import Footer from './Footer';

import landing_bg from '../../../assets/images/landing/landing_section1.jpg';
import landing_card1 from '../../../assets/images/landing/landing_card_1.png';
import landing_card2 from '../../../assets/images/landing/landing_card_2.png';
import landing_card3 from '../../../assets/images/landing/landing_card_3.png';
import landing_section4 from '../../../assets/images/landing/landing_section4.png';

import landing_section4_card1 from '../../../assets/images/landing/landing_section4_card1.svg';
import landing_section4_card2 from '../../../assets/images/landing/landing_section4_card2.svg';
import landing_section4_card3 from '../../../assets/images/landing/landing_section4_card3.svg';

import landing_section5 from '../../../assets/images/landing/landing_section5.png';
import landing_section5_apple from '../../../assets/images/landing/landing_section5_apple.png';
import landing_section5_google from '../../../assets/images/landing/landing_section5_google.png';

import landing_section1_shield from '../../../assets/images/landing/landing_shield.svg';
import landing_section1_women from '../../../assets/images/landing/landing_section1_women.png';

import './index.css';
import StandardPricingcard from './StandardProcingCard';
import { HelpCircle } from 'react-feather';

const SectionData = {
    Section1: {
        text1: "Empower Decisions with Insightful Surveys",
        text2: "HR Survey System is dedicated to enhancing your human resources strategy through comprehensive and customizable surveys. Gain valuable insights to drive employee engagement and organizational growth.",
        text3: "Ensuring confidentiality and precision in data collection",
        button: "Get started"
    },
    Section2: {
        text1: "Create Custom Surveys for Any HR Need",
        text2: "From employee satisfaction to performance evaluations, our system offers flexible survey solutions. Get the feedback you need to make informed HR decisions."
    },
    Section3: {
        text1: "Explore Our Survey Solutions",
        text2: "Discover how our tailored survey tools can transform your HR processes. Explore our range of solutions designed for effective employee feedback and analysis.",
        card: [
            {
                image: landing_card1, // Update image to reflect HR surveys
                title: 'Employee Feedback',
                description: 'Gather honest feedback to improve workplace environment and policies.'
            },
            {
                image: landing_card2, // Update image to reflect HR surveys
                title: 'Performance Evaluation',
                description: 'Conduct thorough performance reviews to foster employee growth and productivity.'
            },
            {
                image: landing_card3, // Update image to reflect HR surveys
                title: 'Engagement Analysis',
                description: 'Measure and enhance employee engagement for a more motivated workforce.'
            }
        ]
    },
    Section4: {
        text1: "Streamline Your HR Processes with Easy-to-Use Surveys",
        text2: "Start your journey towards a more informed HR strategy with our user-friendly survey system. Simplify data collection and analysis for better decision-making."
    },
    Section5: {
        text1: "Our Commitment to Clients",
        card: [
            {
                image: landing_section4_card1, // Update image to reflect HR surveys
                title: 'Data Privacy',
                description: 'Your data is secure with us, ensuring confidentiality in all responses.'
            },
            {
                image: landing_section4_card2, // Update image to reflect HR surveys
                title: '24/7 Support',
                description: 'Our dedicated support team is always available to assist you with any queries.'
            },
            {
                image: landing_section4_card3, // Update image to reflect HR surveys
                title: 'Intuitive Design',
                description: 'Enjoy a seamless survey experience with our intuitive and user-friendly interface.'
            }
        ]
    },
    Section6: {
        text1: "Client Testimonials",
        text2: "Hear from our satisfied users",
        users: [
            // Update with real testimonials from HR survey system users
            {
                name: 'Alex Johnson',
                role: 'HR Manager',
                description: "The HR Survey System has revolutionized how we gather employee feedback. It's efficient and incredibly user-friendly."
            },
            // Add more testimonials as needed
        ]
    },
    SessionFooter: {
        address: {
            title: 'HR Survey System',
            data: [
                '123 Business Ave',
                'Tech Park',
                'City, State, Zip',
            ]
        },
        parts: [
            {
                title: 'Our Company',
                data: [
                    'About Us',
                    'FAQs',
                ]
            },
            {
                title: 'Solutions',
                data: [
                    'Survey Tools',
                ]
            },
            {
                title: 'Legal',
                data: [
                    'Privacy Policy',
                    'Terms of Service',
                ]
            },
            {
                title: 'Contact Us',
                data: [
                    'Email: support@hrsurveysystem.com'
                ]
            },
        ]
    }
}

const faqSessionData = [
    {
        title: "What is eVibeChecks?",
        text: "eVibeChecks is an innovative HR survey platform designed to streamline the process of gathering and analyzing employee feedback. Our tool offers customizable surveys, insightful analytics, and user-friendly interfaces to support your HR decision-making."
    },
    {
        title: "How does eVibeChecks work?",
        text: "It's simple! Select the type of survey you need, distribute it among your employees, and then analyze the results using our intuitive dashboard. Our platform is designed to make the survey process efficient and insightful."
    },
    {
        title: "Is my data safe with eVibeChecks?",
        text: "Absolutely. At eVibeChecks, data security is a top priority. We use advanced encryption and security protocols to ensure that all survey data and personal information are protected."
    },
    {
        title: "What sets eVibeChecks apart from other survey platforms?",
        text: "eVibeChecks stands out for its ease of use, customizable survey options, and powerful analytics. We focus on providing HR teams with the tools they need to gain meaningful insights into employee feedback."
    },
    {
        title: "Can I customize surveys to fit my organization's needs?",
        text: "Yes, our platform allows you to customize surveys to meet the specific needs of your organization. You can choose from a variety of question types, themes, and settings to create surveys that are relevant to your HR objectives."
    },
    {
        title: "Are there any fees associated with using eVibeChecks?",
        text: "We offer various pricing plans to suit different organizational needs. There are no hidden fees, and our pricing structure is transparent and straightforward."
    },
    {
        title: "How do I contact eVibeChecks customer support?",
        text: "Our customer support team is readily available to assist you. You can contact us through the 'Contact Us' section on our platform, where you'll find options for email, live chat, and phone support."
    },
    {
        title: "Can I integrate eVibeChecks with other HR tools?",
        text: "Yes, eVibeChecks is designed to integrate seamlessly with various HR tools and systems, enhancing your HR workflow and data management."
    },
    {
        title: "Can I export survey data for further analysis?",
        text: "Yes, you can easily export survey data from our platform for further analysis or reporting purposes. We support multiple export formats for your convenience."
    },
    {
        title: "Can I delete my account?",
        text: "Yes. If you need to delete your account, please contact our support team for assistance. Note that this action is irreversible and will result in the loss of all stored data."
    },
]

const LandingPage = () => {

    const navigate = useNavigate();
    const handleGetStarted = () => navigate('/login');

    const [isActivity, setIsActivity] = useState([]);
    const handelChange = (e) => {
        let collapseCopy = [...isActivity];
        collapseCopy[e] = !collapseCopy[e];
        setIsActivity(collapseCopy);
    };

    return (
        <div className="landing-page" style={{ position: 'relative' }}>
            <TopHeader />
            <div className='first-section' id={'home'} style={{ backgroundImage: `url(${landing_bg})`, }}>
                <div style={{ zIndex: 1 }}>
                    <div style={{ maxWidth: '1050px', marginTop: '180px', minHeight: '192px' }}>
                        <TypeAnimation
                            sequence={[SectionData.Section1.text1, '', SectionData.Section1.text1, 2000]}
                            className='font96_outfit text-light'
                            cursor={false}
                            speed={200}
                            repeat={Infinity}
                        />
                    </div>
                    <H5 attrH5={{ className: 'font20_outfit text-light', style: { maxWidth: '800px', marginTop: 40, marginBottom: 40 } }}>{SectionData.Section1.text2}</H5>
                    <Fade left>
                        <Col xs={12} className='store-container'>
                            <Image attrImage={{ src: landing_section5_apple, alt: 'section4', }} />
                            <Image attrImage={{ src: landing_section5_google, alt: 'section4', }} />
                        </Col>
                        {/* <Btn attrBtn={{ className: 'font36_outfit text-light', color: 'secondary', style: { width: '245px', height: '110px', borderRadius: '100px' }, onClick: handleGetStarted }}>Get started</Btn> */}
                        <Row className='p-t-40 p-l-15'>
                            <Image attrImage={{ className: 'p-0', src: landing_section1_shield, alt: 'shield', style: { width: 41 } }} />
                            <P attrPara={{ className: 'font-outfit font18 text-light mb-4 mt-4', style: { maxWidth: '50%', display: 'flex', alignItems: 'center' } }}>{SectionData.Section1.text3}</P>
                        </Row>
                    </Fade>
                </div>
                <div className='women-container'>
                    <Image attrImage={{ className: 'p-0', src: landing_section1_women, alt: 'shield' }} />
                </div>
            </div>

            <Fade right>
                <Row className="second-section">
                    <H1 attrH1={{ className: 'font80_outfit text-dark text-center', style: { maxWidth: '70%', marginTop: '150px' } }}>{SectionData.Section2.text1}</H1>
                    <Col xs={12} className='d-flex align-items-center justify-content-center'>
                        <H1 attrH1={{ className: 'font20_outfit text-dark text-center', style: { maxWidth: '750px', marginTop: '50px', lineHeight: 1.5 } }}>{SectionData.Section2.text2}</H1>
                    </Col>
                    <Col xs={12} className='d-flex align-items-center justify-content-center m-t-50'>
                        <Btn attrBtn={{ className: 'font14_outfit text-light', onClick: handleGetStarted, color: 'secondary', style: { width: '186px', height: '64px', borderRadius: '100px' } }}>Get Started</Btn>
                    </Col>

                </Row>
            </Fade>

            <Fade bottom>
                <Row className="third-section">
                    <H1 attrH1={{ className: 'font80_outfit text-dark', style: { maxWidth: '800px', marginTop: '90px' } }}>{SectionData.Section3.text1}</H1>
                    <Col xs={12}>
                        <H5 attrH5={{ className: 'font20_outfit text-dark m-4', style: { maxWidth: '700px' } }}>{SectionData.Section3.text2}</H5>
                    </Col>
                </Row>
            </Fade>

            <div id="savings">
                <Row className="second-section">
                    {SectionData.Section3.card.map(item =>
                        <Fade right>
                            <div className='w-100 d-flex justify-content-center'>
                                <Col xs={10}>
                                    <Card body>
                                        <Row className='m-4'>
                                            <Col xxl={3} xl={3} xs={12} className='d-flex justify-content-center align-items-center'>
                                                <Image attrImage={{ src: item.image, alt: item.title, style: { height: '200px' } }} />
                                            </Col>
                                            <Col xxl={9} xl={9} xs={12}>
                                                <Row className="card-section">
                                                    <H1 attrH1={{ className: 'font64_outfit text-dark' }}>{item.title}</H1>
                                                    <Col xs={12}>
                                                        <H5 attrH5={{ className: 'font20_outfit text-dark' }}>{item.description}</H5>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <Btn attrBtn={{ className: 'font14_outfit text-light', onClick: handleGetStarted, color: 'secondary', style: { width: '186px', height: '64px', borderRadius: '100px' } }}>Get Started</Btn>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </div>
                        </Fade>
                    )}
                </Row>
            </div>

            <div id={'features'}>
                <Fade right>
                    <Row className='fourth-section'>
                        <Col xxl={4} lg={12} className='d-flex justify-content-center align-items-center'>
                            <div className='section4_card'>
                                <Image attrImage={{ src: landing_section4, alt: 'section4', style: { height: '400px', width: 'auto' } }} />
                            </div>
                        </Col>
                        <Col xxl={8} lg={12}>
                            <Row className="card-section">
                                <H1 attrH1={{ className: 'font60_outfit text-dark', style: { maxWidth: '800px', marginTop: '90px' } }}>{SectionData.Section4.text1}</H1>
                                <Col xs={12}>
                                    <H5 attrH5={{ className: 'font20_outfit text-dark' }}>{SectionData.Section4.text2}</H5>
                                </Col>
                                <Col xs={12}>
                                    <Btn attrBtn={{ className: 'font14_outfit text-light', onClick: handleGetStarted, color: 'secondary', style: { width: '186px', height: '64px', borderRadius: '100px' } }}>Get Started</Btn>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Fade>
            </div>

            <Row className="fifth-section">
                <H1 attrH1={{ className: 'font64_outfit text-light', style: { textAlign: 'center', margin: '20px 0 40px 0' } }}>{SectionData.Section5.text1}</H1>
                <Col xxl={6} lg={6}>
                    <Zoom>
                        <div className='d-flex justify-content-center align-items-center m-t-50'>
                            <Image attrImage={{ src: landing_section5, alt: 'section4', style: { height: 'auto', width: '50%' } }} />
                        </div>
                    </Zoom>
                </Col>
                <Col xxl={4} lg={6} xl={5} className='m-t-50'>
                    {SectionData.Section5.card.map(item =>
                        <Fade right cascade big>
                            <Card body>
                                <Image attrImage={{ src: item.image, alt: 'section4', style: { height: '40px', width: '40px' } }} />
                                <Row className="card-section" style={{ gap: 0, marginTop: 5 }}>
                                    <H5 attrH5={{ className: 'font20_outfit text-dark', style: { fontWeight: 700 } }}>{item.title}</H5>
                                    <Col xs={12} className='mt-0'>
                                        <P attrPara={{ className: 'font16_dmsans text-dark m-t-5', style: { fontWeight: 500, lineHeight: '20px' } }}>{item.description}</P>
                                    </Col>
                                </Row>
                            </Card>
                        </Fade>
                    )}
                </Col>

            </Row >

            <Row className="fourth-section">
                <div id={'pricing'}>
                    <H1 attrH1={{ className: 'font64_outfit text-dark', style: { textAlign: 'center', margin: '20px 0 40px 0' } }}>{'pricing'}</H1>
                    <StandardPricingcard />
                </div>
            </Row>

            <Row className="fourth-section">
                <div id={'faqs'}>
                    <H2 attrH2={{ className: 'm-4 font40-bold font-outfit text-dark text-center' }} >{'Frequently Asked Questions (FAQs) - Mothrift'}</H2>
                    <Row className='about-section' style={{ margin: '40px' }}>
                        <Col xl='8' lg='6' md='7' className='box-col-8 xl-60'>
                            {faqSessionData && faqSessionData.map((item, i) => {
                                return (
                                    <Fade right>
                                        <Card className='font-outfit pt-4 pb-4' key={i} onClick={() => handelChange(i)} style={{ borderRadius: 8 }}>
                                            <CardHeader className='pb-0 mb-0 pt-0' style={{ borderBottomWidth: 0 }}>
                                                <div className='d-flex' style={{ alignItems: 'center', gap: 15 }}>
                                                    <HelpCircle size={28} />
                                                    <H3 attrH3={{ className: 'mb-0 font-bold text-grey font-outfit' }}>{item.title}</H3>
                                                </div>
                                            </CardHeader>
                                            <Collapse isOpen={isActivity[i]}>
                                                <CardBody>
                                                    <H5 attrH5={{ className: 'font-outfit' }}>{item.text}</H5>
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                    </Fade>
                                );
                            })}
                        </Col>
                        {/* <H3 attrH3={{ className: 'font-outfit' }}>{'These FAQs aim to provide clarity on common questions users may have about MoThrift, ensuring a transparent and user-friendly experience.'}</H3> */}
                    </Row>
                </div>
            </Row>

            {/* <div className="sixth-section" style={{ position: 'relative' }}>

                <div className='d-flex' style={{ flexDirection: 'column' }}>
                    <div className='font64_outfit text-dark text-center'>{SectionData.Section6.text1}</div>
                    <div className='font20_outfit text-dark text-center' style={{ fontWeight: 400 }}>{SectionData.Section6.text2}</div>
                </div>

                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    onSwiper={(swiper) => setSwiper(swiper)}
                >
                    {SectionData.Section6.users.map((item, i) =>
                        <SwiperSlide key={`${item.name}_${i}`}>
                            <Row>
                                <Col xs={4} className='d-flex justify-content-center align-items-center'>
                                    <Image attrImage={{ src: landing_section6, alt: 'section6', style: { height: 'auto', width: '300px' } }} />
                                </Col>
                                <Col xs={8} className="d-flex justify-content-center align-items-center">
                                    <Row className="card-section" >
                                        <span className='font40_outfit text-dark'>{item.name}</span>
                                        <span className='font16_outfit text-dark'>{item.role}</span>
                                        <Col xs={12}>
                                            <H5 attrH5={{ className: 'font20_outfit text-dark' }}>{item.description}</H5>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </SwiperSlide>
                    )}
                </Swiper>

                <div className='font20_outfit d-flex' style={{ position: 'absolute', bottom: 40, right: 50 }}>
                    <div className='me-1 d-flex' onClick={handleSection6Prev} style={{ justifyContent: 'center', alignItems: 'center', background: '#5551', borderRadius: 100, width: 40, height: 40, cursor: 'pointer' }}>
                        <i className="fa fa-regular fa-chevron-left" style={{ color: '#666' }}></i>
                    </div>
                    <div onClick={handleSection6Next} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#5551', borderRadius: 100, width: 40, height: 40, cursor: 'pointer' }}>
                        <i className="fa fa-regular fa-chevron-right" style={{ color: '#666' }}></i>
                    </div>
                </div>

            </div > */}

            {/* Footer */}
            <Footer />

        </div >
    );
};

export default LandingPage;
