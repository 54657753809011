import React, { Fragment } from 'react'
import DataTable from 'react-data-table-component';
import { convertDate, convertDate2, convertDateTime, formatCurrency } from '../../../../Library/custom_utils';
import { H3, H6 } from '../../../../AbstractElements';
import SurveyLoader from '../../Common/SurveyLoader';

const tableColumns = [
    {
        name: 'User ID',
        selector: row => row['userId'],
        sortable: true,
        center: false,
    },
    {
        name: 'Interest',
        selector: row => row['amount'],
        sortable: true,
        prefix: '@@@',
        center: false,
    },
    {
        name: 'Date',
        selector: row => row['date'],
        sortable: true,
        center: true,
    },
    {
        name: 'Term',
        selector: row => row['fixType'],
        sortable: true,
        center: true,
    },
    {
        name: 'Date Started',
        selector: row => row['fixStarted'],
        sortable: true,
        center: true,
    },
    {
        name: 'End Date',
        selector: row => row['fixEnded'],
        sortable: true,
        center: true,
    },
    {
        name: 'Amount',
        selector: row => row['fixAmount'],
        sortable: true,
        center: true,
    }
];

const DataTableComponent = ({ data, isLoading }) => {

    const renderItem = (item) => {

        return {
            userId: <H6 attrH6={{ className: 'f-14 f-light' }}>{item.userId}</H6>,
            amount: <H3 attrH3={{ className: 'f-14 mb-0 font-success font16-bold' }}>{formatCurrency(item.amount)}</H3>,
            date: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{convertDateTime(item.date)}</H6>,
            fixType: <H6 attrH6={{ className: 'f-14 mb-2 f-light' }}>{`${item.fixType == 1 ? '2 hours' : `${item.fixType} days`}`}</H6>,
            fixStarted: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{convertDate(item.fixStarted)}</H6>,
            fixEnded: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{convertDate(item.fixFinished)}</H6>,
            fixAmount: <H3 attrH3={{ className: 'f-14 mb-0 font-success font16-bold' }}>{formatCurrency(item.fixAmount)}</H3>,
        }
    }

    return (
        <Fragment>
            {isLoading ? <SurveyLoader /> :
                <DataTable
                    data={data.map((item, i) => renderItem(item, i))}
                    columns={tableColumns}
                    striped
                    center
                    paginationPerPage={20}
                    pagination
                />}
        </Fragment>
    )
}
export default DataTableComponent