import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Input, Row } from 'reactstrap';
import SurveyLogo from '../Common/SurveyLogo';
import { H1, H4 } from '../../../AbstractElements';
import { Image } from 'react-bootstrap';
import { COLORS, IMAGES } from '../../../Library/theme';
import './index.css'
import { useParams } from 'react-router';
import { checkSurveyAPI, submitSurveyAPI } from '../../../Library/apis';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import * as animationData from '../../../assets/images/done.json';
import Lottie from 'react-lottie';
import { CONSTANTS } from './../../../Library/theme';

const emoticSize = 140;
const emoticImageData = [
    IMAGES.emotic1,
    IMAGES.emotic2,
    IMAGES.emotic3,
    IMAGES.emotic4,
    IMAGES.emotic5,
];

const ESurvey = () => {

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedButtons, setSelectedButtons] = useState({});
    const [comment, setComment] = useState('');

    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { token } = useParams();
    console.log({ token })

    const handleClick = (key) => {
        setSelectedButtons(prev => {
            return { ...prev, [key]: !prev[key] };
        });
    }

    const initState = () => {
        setSelectedIndex(-1);
        setSelectedButtons({});
        setComment('');
    }

    const handleSubmit = () => {
        submitSurveyAPI({
            token,
            emotion: selectedIndex,
            reason: selectedButtons,
            comment
        }).then(res => {
            toast.success(res.message, { autoClose: 1000 });
            setIsSubmitted(true);
            initState();
        }).catch(e => {
            toast.error(e.response.data?.message || 'Error', { autoClose: 1000 });
        })
    }

    const handleSave = () => {

    }

    useEffect(() => {
        setLoading(true);
        checkSurveyAPI({ token }).then(() => {
            setIsSubmitted(false);
            setLoading(false);
        }).catch(() => {
            setIsSubmitted(true);
            setLoading(false);
        });

    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Fragment>
            <SurveyLogo />
            <div className='login-card'>
                <Card className='p-5 m-t-50 gap-3' style={{ width: '90%', minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                    {
                        loading ?
                            <PulseLoader color={COLORS.primary} size={50} />
                            :
                            isSubmitted
                                ?
                                <>
                                    <H1 attrH1={{ className: 'font-outfit font48', style: { color: COLORS.primary } }}>{'Thanks for your survey!'}</H1>
                                    <Lottie options={defaultOptions}
                                        height={400}
                                        width={400}
                                    />
                                </>
                                :
                                <>
                                    <H1>{'How are you feeling at the moment?'}</H1>
                                    <div className='d-flex gap-4 pt-4'>
                                        {emoticImageData.map((item, index) => <Image key={`emotic-image-${index}`} onClick={() => { setSelectedIndex(index) }} className={`emotic-image ${selectedIndex == index ? 'active' : ''}`} width={emoticSize} src={item}></Image>)}
                                    </div>
                                    <div className='pt-4'>
                                        <H4>{'Select a reason (multiple choice possible)'}</H4>
                                    </div>
                                    <div className='d-flex gap-4 pt-4 justify-content-center' style={{ flexWrap: 'wrap' }}>
                                        {CONSTANTS.workStationData.map(item => <div key={item.key} className={`workstation-button ${!selectedButtons[item.key] ? 'active' : ''}`} onClick={() => { handleClick(item.key) }}>{item.value}</div>)}
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <Input
                                            id="comment-text"
                                            name="text"
                                            placeholder='Your comment (optional)'
                                            type="textarea"
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                        />
                                    </div>
                                    <div className='d-flex w-75' style={{ justifyContent: 'space-evenly' }}>
                                        <Button onClick={handleSubmit}>{'Submit'}</Button>
                                        <Button onClick={handleSave} color='success'>{'Save'}</Button>
                                    </div>
                                </>
                    }
                </Card>
            </div >
        </Fragment >
    );
};

export default ESurvey;
