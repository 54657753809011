import React from 'react';
import { Card, CardBody, } from 'reactstrap';
import TermSavingsTableData from './TermSavingsTableData';
import SubHeader from '../../Common/SubHeader';

const TermSavingsTable = ({ data, isLoading }) => {

  return (
    <>
      <SubHeader title="Term Savings" data={data} />
      <Card className='tranaction-card'>
        <CardBody className='pt-4'>
          <div className={`show table-responsive recent-table transaction-table`}>
            <TermSavingsTableData data={data} isLoading={isLoading} />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default TermSavingsTable;
