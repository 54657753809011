import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Breadcrumbs, H1, H3, H5 } from '../../../AbstractElements';
import _ from 'lodash';
import { Container, Row, Card, CardBody, Col, Button, Toast } from 'reactstrap';
import './index.css';
import { getSurveyAPI } from '../../../Library/apis';
import { useParams } from 'react-router';
import { Copy } from 'react-feather';
import { toast } from 'react-toastify';
import TotalValueWidget from './TotalValueWidget';

const SurveyDetails = () => {

    const [data, setData] = useState([]);
    const { id } = useParams();

    const [analysis, setAnalysis] = useState({
        happyMembers: 0,
        inviteNumber: 0,
        responseNumber: 0,
        responseRate: 0
    });

    useEffect(() => {
        if (id) {
            getSurveyAPI({ id }).then((res) => {
                setData(res.body);
                if (res.body.analysis) setAnalysis(res.body.analysis);
                console.log(res.body)
            }).catch(console.log);
        }
    }, [id]);

    const handleCopyLink = (link) => {
        navigator.clipboard.writeText(link)
            .then(() => {
                toast.success('Link copied to clipboard', { autoClose: 1000 });
            })
            .catch(err => {
                toast.error('Failed to copy link: ', { autoClose: 1000 });
            });
    }

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Survey Details' />
            <Container fluid={true}>
                {data.survey?.link && <Card className='tranaction-card p-3'>
                    <CardBody className='pt-2 ps-2 pb-2'>
                        <H5>{'Survey Link:'}</H5>
                        <div className='d-flex align-items-center' style={{ justifyContent: 'space-between' }}>
                            <div className='font-outfit font18 ms-4' style={{ maxWidth: '90%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>
                                {data.survey.link}
                            </div>
                            <div className='icon-button survey-button' onClick={() => { handleCopyLink(data.survey.link) }}>
                                <Copy color={'#ffffff'} size={15} />
                            </div>
                        </div>
                    </CardBody>
                </Card>}
            </Container >
            <TotalValueWidget data={analysis} />
        </Fragment >
    );
};

export default SurveyDetails;
