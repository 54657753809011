import React, { Fragment, useContext, useEffect, useState } from "react";
import { Row, Col, Modal, ModalHeader, Form, FormGroup, Label, Input, Spinner, Button, ModalBody } from 'reactstrap'
import { toast } from "react-toastify";
import UserContext from "../../../../_helper/User";
import { updateDepartmentAPI } from "../../../../Library/apis";

const EditDepartmentsModal = ({ modal, toggle, selectedRole }) => {

    const { updateDepartments } = useContext(UserContext)

    const [formValues, setFormValues] = useState({
        id: '',
        name: "",
    });

    useEffect(() => {
        if (selectedRole) {
            const name = selectedRole.name;
            setFormValues({ id: selectedRole._id, name });
        }
    }, [selectedRole])

    const onEditSubmit = (e) => {
        updateDepartmentAPI(
            {
                id: formValues.id,
                name: formValues.name,
            }).then(res => {
                toast.success("Successfully Updated!", { autoClose: 1000 });
                updateDepartments();
                toggle();
            }).catch(e => {
                toast.error("Update failed!", { autoClose: 1000 });
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    return (
        <Modal isOpen={modal} toggle={toggle} centered size={'lg'} className="font-outfit">
            <ModalHeader toggle={toggle} >{'Edit Department'}</ModalHeader>
            <ModalBody>
                <Row>
                    <FormGroup className="mb-3">
                        <Label className="form-label">{'Department Name'}</Label>
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Position Name *"
                            name="name"
                            required
                            value={formValues?.name || ""}
                            onChange={handleChange}
                        />
                    </FormGroup>
                </Row>
                <div className="text-end">
                    <Button color="primary" onClick={onEditSubmit}>{'Update'}</Button>
                </div>
            </ModalBody >
        </Modal>
    );
};

export default EditDepartmentsModal;
