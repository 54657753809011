import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Container, Row, Card, CardBody, Col, Label, Input } from 'reactstrap';
import { Breadcrumbs, H5 } from '../../../AbstractElements';
import UserContext from '../../../_helper/User';
import VerticalStackBar from './VerticalStacketBar';
import { getSurveyAllDetailsAPI } from '../../../Library/apis';
import { toast } from 'react-toastify';

const SurveyManagementDetails = () => {
    const { departments } = useContext(UserContext);
    const [departmentChecks, setDepartmentChecks] = useState({});
    const [emojiChecks, setEmojiChecks] = useState({
        happy: true,
        smiley: true,
        indifferent: true,
        worried: true,
        angry: true
    });

    const handleDepartmentChange = (id) => {
        setDepartmentChecks(prev => ({ ...prev, [id]: !prev[id] }));
    };

    const handleEmojiChange = (emoji) => {
        setEmojiChecks(prev => ({ ...prev, [emoji]: !prev[emoji] }));
    };

    const emojis = ['happy', 'smiley', 'indifferent', 'worried', 'angry'];

    const [totalValues, setTotalValues] = useState({
        totalHappyNumber: 0,
        totalInviteNumber: 0,
        totalResponseNumber: 0,
        totalResponseRate: 0,
        totalEmotionCounts: {},
        totalReasonCounts: {},
        emotionDepartmentAnalysis: {}
    });

    useEffect(() => {
        getSurveyAllDetailsAPI().then(res => {
            console.log(res);
            setTotalValues(res.body?.analysis);
        }).catch((e) => {
            toast.error("Error while getting survey details");
        });
    }, []);

    return (
        <Fragment>
            <Container fluid={true}>
                <Breadcrumbs mainTitle='Survey Details' />
                <Card>
                    <CardBody className="chart-block">
                        <Row>
                            <Col xs={10}>
                                <H5>Top Reasons for Emojis</H5>
                                <VerticalStackBar
                                    departmentChecks={departmentChecks}
                                    emojiChecks={emojiChecks}
                                    data={totalValues.totalReasonCounts}
                                />
                            </Col>
                            <Col xs={2}>
                                <div className='m-4 gap-4 d-flex' style={{ flexDirection: 'column' }}>
                                    <div>
                                        <H5>Department</H5>
                                        {departments.map((department) => (
                                            <Label key={department._id} className="d-block" for={`chk-ani-${department._id}`}>
                                                <Input
                                                    className="checkbox_animated"
                                                    id={`chk-ani-${department._id}`}
                                                    type="checkbox"
                                                    checked={departmentChecks[department._id] || false}
                                                    onChange={() => handleDepartmentChange(department._id)}
                                                />
                                                {department.name}
                                            </Label>
                                        ))}
                                    </div>
                                    <div>
                                        <H5>Emoji</H5>
                                        {emojis.map((emoji) => (
                                            <Label key={emoji} className="d-block" for={`chk-ani_${emoji}`}>
                                                <Input
                                                    className="checkbox_animated"
                                                    id={`chk-ani_${emoji}`}
                                                    type="checkbox"
                                                    checked={emojiChecks[emoji]}
                                                    onChange={() => handleEmojiChange(emoji)}
                                                />
                                                {emoji.charAt(0).toUpperCase() + emoji.slice(1)}
                                            </Label>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
}

export default SurveyManagementDetails;
