import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';

import PieChartClass from './PieChart';
import StackBar from './StacketBar';
import VerticalStackBar from './VerticalStacketBar';

const Charts = ({ data }) => {

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <StackBar data={data?.emotionDepartmentAnalysis} />
          <PieChartClass data={data.totalEmotionCounts} />
          <VerticalStackBar data={data.totalReasonCounts} />
        </Row>
      </Container>
    </Fragment>
  );
};

export default Charts;