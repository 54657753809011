import React, { Fragment, useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Badges, Btn, H3, H4, H5, H6 } from '../../../../AbstractElements';
import { deleteDepartmentAPI, deleteStaffRoleAPI, getAllStaffsAPI, getRoleListsAPI, getStaffRolesAPI } from '../../../../Library/apis';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Delete, Edit, Eye, Trash, Trash2 } from 'react-feather';
import UserContext from '../../../../_helper/User';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import SubHeader from '../../Common/SubHeader';
import SurveyLoader from '../../Common/SurveyLoader';
import AddDepartmentsModal from './AddDepartmentsModal';
import EditDepartmentsModal from './EditDepartmentsModal';

const tableColumns = [
    {
        name: 'Name',
        selector: row => row['name'],
        sortable: true,
        center: false,
        width: '300px'
    },
    {
        name: 'Manager',
        selector: row => row['manager'],
        sortable: false,
        center: false,
    },
    {
        name: 'Action',
        selector: row => row['action'],
        center: true,
        width: '150px'
    },
];

const DepartmentsTable = () => {

    const { userInfo, departments, updateDepartments } = useContext(UserContext);

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [showEditModal, setEditModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRole, setSelectedRole] = useState();

    const isHasEditStaffRole = userInfo.roles && userInfo.roles.includes("Add/Update Department");

    useEffect(() => {
        if (selectedRole) setEditModal(true);
    }, [selectedRole])

    const renderItem = (item, handleEdit) => {

        const handleDelete = (id) => {
            Swal.fire({
                title: 'Do you want delete this Staff Position?',
                text: "If Yes, then some staffs rose their position.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete'
            }).then((result) => {
                if (result.isConfirmed) {
                    deleteDepartmentAPI({ id }).then(res => {
                        toast.success(res.message, { autoClose: 1000 });
                        setSelectedRole(null);
                        updateDepartments();
                    }).catch(() => {
                        toast.error("Delete failed!", { autoClose: 1000 });
                    });
                }
            });
        }

        return {
            name: <H6 attrH6={{ className: 'ms-2 mt-2' }}>{item?.name}</H6>,
            manager:
                <H6 attrH6={{ className: 'ms-2 mt-2' }}>{item?.managerId?.managerName || ''}</H6>,
            action:
                <div style={{ display: 'flex', gap: 10 }}>
                    {isHasEditStaffRole && <>
                        <div onClick={handleEdit} style={{ cursor: 'pointer' }} className='me-2'>
                            <Edit color={'#4D4D4D'} size={16} />
                        </div>
                        <div onClick={() => { handleDelete(item._id) }} style={{ cursor: 'pointer' }} className='me-2'>
                            <Trash2 color={'#4D4D4D'} size={16} />
                        </div>
                    </>}
                </div>
        }
    }

    return (
        <>
            <SubHeader title="All Departments" data={departments} />
            <Card className='tranaction-card'>
                <CardBody className='pt-4'>
                    <Btn attrBtn={{ color: 'primary', onClick: () => { toggle(); }, className: 'mb-3 font16', style: { height: 40 } }}>{'+ Add New Department'}</Btn>
                    <div className={`show table-responsive recent-table transaction-table`}>
                        {isLoading ? <SurveyLoader /> :
                            <DataTable
                                data={departments.map((item, i) => renderItem(item, () => { setSelectedRole(item) }))}
                                columns={tableColumns}
                                striped
                                center
                            />}
                    </div>
                </CardBody>
            </Card>

            <AddDepartmentsModal
                modal={modal}
                toggle={() => {
                    toggle();
                    setSelectedRole(null);
                }}
            />

            <EditDepartmentsModal
                modal={showEditModal}
                toggle={() => {
                    setEditModal(false);
                    setSelectedRole(null);
                }}
                selectedRole={selectedRole}
            />

        </>
    )
}
export default DepartmentsTable