import React, { Fragment, useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Badges, Btn, H3, H4, H5, H6 } from '../../../../AbstractElements';
import { deleteStaffRoleAPI, getAllStaffsAPI, getRoleListsAPI, getStaffRolesAPI } from '../../../../Library/apis';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Delete, Edit, Eye, Trash, Trash2 } from 'react-feather';
import AddPositionModal from './AddPositionModal';
import EditPositionModal from './EditPositionModal';
import UserContext from '../../../../_helper/User';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import SubHeader from '../../Common/SubHeader';
import SurveyLoader from '../../Common/SurveyLoader';

const tableColumns = [
    {
        name: 'Name',
        selector: row => row['name'],
        sortable: true,
        center: false,
        width: '200px'
    },
    {
        name: 'Positions',
        selector: row => row['position'],
        sortable: false,
        center: false,
    },
    {
        name: 'Role Counts',
        selector: row => row['number'],
        sortable: false,
        center: false,
        width: '150px'
    },
    {
        name: 'Action',
        selector: row => row['action'],
        center: true,
        width: '150px'
    },
];

const PositionTable = () => {

    const { userInfo, staffRoles, updateStaffRoles } = useContext(UserContext);

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [showEditModal, setEditModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRole, setSelectedRole] = useState();

    const isHasEditStaffRole = userInfo.roles && userInfo.roles.includes("Add/Update Staff");

    useEffect(() => {
        if (selectedRole) setEditModal(true);
    }, [selectedRole])

    const [rights, setRights] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        getRoleListsAPI().then((res) => {
            setIsLoading(false);
            setRights(() => {
                return res.roles.map((role, i) => ({ value: i, label: role }))
            })
        }).catch(() => {
            setIsLoading(false);
        })
    }, []);

    const renderItem = (item, handleEdit) => {

        const handleDelete = (id) => {
            Swal.fire({
                title: 'Do you want delete this Staff Position?',
                text: "If Yes, then some staffs rose their position.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete'
            }).then((result) => {
                if (result.isConfirmed) {
                    deleteStaffRoleAPI({ positionId: id }).then(res => {
                        toast.success(res.message, { autoClose: 1000 });
                        setSelectedRole(null);
                        updateStaffRoles();
                    }).catch(() => {
                        toast.error("Delete failed!", { autoClose: 1000 });
                    });
                }
            });
        }

        return {
            name: <H6 attrH6={{ className: 'ms-2 mt-2' }}>{item?.positionName}</H6>,
            position:
                <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                    {item?.roles.map(role =>
                        <div className='p-1' key={role}>
                            <Badges attrBadge={{ className: 'badge rounded-pill font14', color: 'success', pill: true }}>{role}</Badges>
                        </div>
                    )}
                </div>,
            number: <H6 attrH6={{ className: 'ms-2 mt-2 text-muted' }}>{`${item?.roles?.length}/${rights.length}`}</H6>,
            action:
                <div style={{ display: 'flex', gap: 10 }}>
                    {isHasEditStaffRole && <>
                        <div onClick={handleEdit} style={{ cursor: 'pointer' }} className='me-2'>
                            <Edit color={'#4D4D4D'} size={16} />
                        </div>
                        <div onClick={() => { handleDelete(item._id) }} style={{ cursor: 'pointer' }} className='me-2'>
                            <Trash2 color={'#4D4D4D'} size={16} />
                        </div>
                    </>}
                </div>
        }
    }

    return (
        <>
            <SubHeader title="All Staff Positions" data={staffRoles} />
            <Card className='tranaction-card'>
                <CardBody className='pt-4'>
                    <Btn attrBtn={{ color: 'primary', onClick: () => { toggle(); }, className: 'mb-3 font16', style: { height: 40 } }}>{'+ Add New Position'}</Btn>
                    <div className={`show table-responsive recent-table transaction-table`}>
                        {isLoading ? <SurveyLoader /> :
                            <DataTable
                                data={staffRoles.map((item, i) => renderItem(item, () => { setSelectedRole(item) }))}
                                columns={tableColumns}
                                striped
                                center
                            />}
                    </div>
                </CardBody>
            </Card>

            <AddPositionModal
                modal={modal}
                toggle={() => {
                    toggle();
                    setSelectedRole(null);
                }}
                roles={rights}
            />

            <EditPositionModal
                modal={showEditModal}
                toggle={() => {
                    setEditModal(false);
                    setSelectedRole(null);
                }}
                selectedRole={selectedRole}
                roles={rights}
            />

        </>
    )
}
export default PositionTable