import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { COLORS } from '../../../../Library/theme';

export const WidgetsRadialChart = {
  series: [],
  options: {
    chart: {
      height: 150,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '80%',
        },
        track: {
          strokeWidth: '20%',
          opacity: 1,
          margin: 5,
        },
        dataLabels: {
          show: false,
          total: {
            show: false,
          }
        },
      },
    },
    colors: [],
    stroke: {
      lineCap: 'round',
    },
    responsive: [
      {
        breakpoint: 1500,
        options: {
          chart: {
            height: 130,
          },
        },
      },
    ],
  },
};

const RadialChart = ({ value = -10 }) => {

  const color = value < 0 ? COLORS.danger : value < 25 ? COLORS.warning : COLORS.success;

  const getStatusTitle = (value) => {
    if (value <= -80) return 'Bad';
    if (value <= -20) return 'Worried';
    if (value <= 20) return 'Indifferent';
    if (value <= 80) return 'Good';
    return 'Happy';
  };

  const updatedOption = {
    ...WidgetsRadialChart,
    series: [40 + (value) * 0.4],
    options: {
      ...WidgetsRadialChart.options,
      plotOptions: {
        ...WidgetsRadialChart.options.plotOptions,
        radialBar: {
          ...WidgetsRadialChart.options.plotOptions.radialBar,
          dataLabels: {
            ...WidgetsRadialChart.options.plotOptions.radialBar.dataLabels,
            value: {
              ...WidgetsRadialChart.options.plotOptions.radialBar.dataLabels.value,
              show: false,
            },
          }
        }
      },
      colors: [color],
    },
  };
  
  return (
    <>
      <div style={{ position: 'relative', fontFamily: 'Outfit' }}>
        <div style={{ transform: 'rotate(215deg)' }}>
          <ReactApexChart type='radialBar' height={350} series={updatedOption.series} options={updatedOption.options} />
        </div>
        <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, display: 'flex', justifyContent: 'center' }}>
          <div style={{ fontSize: '80px', fontWeight: 700, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>{value}</div>
          <div style={{ fontSize: '30px', position: 'absolute', top: -10, fontWeight: 700, display: 'flex', flexDirection: 'column' }}>{'0'}</div>
          <div style={{ fontSize: '30px', position: 'absolute', bottom: 0, left: 100, fontWeight: 700, display: 'flex', flexDirection: 'column' }}>{'-100'}</div>
          <div style={{ fontSize: '30px', position: 'absolute', bottom: 0, right: 100, fontWeight: 700, display: 'flex', flexDirection: 'column' }}>{'100'}</div>
        </div>
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ fontSize: '40px', fontWeight: 700 }}>{getStatusTitle(value)}</div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <div style={{ fontSize: '24px', color: COLORS.success, fontWeight: 700 }}>{'+ 2pt'}</div>
          <div style={{ fontSize: '20px', }}>{'in th past 30 days'}</div>
        </div>
      </div>
    </>
  );
};


export default RadialChart;
