import CodeVerification from '../Components/Survey/Auth/CodeVerification';
import ForgetPwd from '../Components/Survey/Auth/ForgotPassword';
import LoginMain from '../Components/Survey/Auth/LogIn';
import SignUp from '../Components/Survey/Auth/SignUp';

import About from '../Components/Survey/LandingPage/About';
import FAQPage from '../Components/Survey/LandingPage/Faq';
import TermsPage from '../Components/Survey/LandingPage/Terms';
import PrivacyPage from '../Components/Survey/LandingPage/Privacy';
import LandingPage from '../Components/Survey/LandingPage';
import ESurvey from '../Components/Survey/eSurvey';

export const authRoutes = [
  
  { path: `/login`, Component: <LoginMain /> },
  { path: `/survey/auth/forget-pwd`, Component: <ForgetPwd /> },
  { path: `/survey/auth/code-verification`, Component: <CodeVerification /> },
  { path: '/survey/auth/sign-up', Component: <SignUp /> },
  { path: '/esurvey/:token', Component: <ESurvey /> },

  { path: '/home', Component: <LandingPage /> },

  { path: '/about', Component: <About /> },
  { path: '/faq', Component: <FAQPage /> },
  { path: '/terms', Component: <TermsPage /> },
  { path: '/privacy-policy', Component: <PrivacyPage /> },
  
];
