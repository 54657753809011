import React, { useEffect, useState } from 'react';
import Context from './index';

const ProductProvider = (props) => {
    const [productItem, setProductItem] = useState([]);
    const symbol = '$';

    return (
        <Context.Provider value={{ ...props, productItem, symbol }}>
            {props.children}
        </Context.Provider>
    );
};

export default ProductProvider;
