import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import Callback from '../Auth/Callback';
import Loader from '../Layout/Loader';
import { authRoutes } from './AuthRoutes';
import LayoutRoutes from '../Route/LayoutRoutes';
import PrivateRoute from './PrivateRoute';
import LoginMain from '../Components/Survey/Auth/LogIn';

toast.configure();

const Routers = () => {
  const jwt_token = localStorage.getItem('token');

  useEffect(() => {
    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
    console.disableYellowBox = true;
  }, []);

  return (
    <BrowserRouter basename={'/'}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={'/'} element={<PrivateRoute />}>
            {jwt_token ? (
              <>
                <Route exact path={""} element={<Navigate to={"/survey/survey-overview"} />} />
                <Route exact path={"/"} element={<Navigate to={"/survey/survey-overview"} />} />
              </>
            ) : (
              ''
            )}
            <Route path={"/*"} element={<LayoutRoutes />} />
          </Route>
          <Route path={"/callback"} render={() => <Callback />} />
          <Route exact path={"/login"} element={<LoginMain />} />
          {authRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={Component} key={i} />
          ))}
        </Routes>
      </Suspense>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default Routers;
