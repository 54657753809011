import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getBalanceInfoAPI, setBalanceInfoAPI } from '../../../Library/apis';
import Widget from './Widget';
import { formatCurrency, formatPercentage } from '../../../Library/custom_utils';

const BalanceInfo = () => {

    const handleSetInterestRate = (value, toggle) => {
        if (!value) {
            toast.warning('Please Input Positive value', { autoClose: 1000 });
        } else {
            setBalanceInfoAPI({ interestRate: value }).then(res => {
                toggle && toggle();
                toast.success(res.message, { autoClose: 1000 });
                updateBalanceInfo();
            }).catch(e => {
                toast.error('Set Value Error', { autoClose: 1000 })
            })
        }
    }

    const handleSetRefferalBonus = (value, toggle) => {
        if (!value) {
            toast.warning('Please Input Positive value', { autoClose: 1000 });
        } else {
            setBalanceInfoAPI({ refferalBonus: value }).then(res => {
                toggle && toggle();
                toast.success(res.message, { autoClose: 1000 });
                updateBalanceInfo();
            }).catch(e => {
                toast.error('Set Value Error', { autoClose: 1000 })
            })
        }
    }

    const handleMinBonusRate = (value, toggle) => {
        if (!value) {
            toast.warning('Please Input Positive value', { autoClose: 1000 });
        } else {
            setBalanceInfoAPI({ min_refferal_bonus_fix_amount: value }).then(res => {
                toggle && toggle();
                toast.success(res.message, { autoClose: 1000 });
                updateBalanceInfo();
            }).catch(e => {
                toast.error('Set Value Error', { autoClose: 1000 })
            })
        }
    }

    const handleTermSavingsThreshold = (value, toggle) => {
        if (!value) {
            toast.warning('Please Input Positive value', { autoClose: 1000 });
        } else {
            setBalanceInfoAPI({ term_savings_threshold: value }).then(res => {
                toggle && toggle();
                toast.success(res.message, { autoClose: 1000 });
                updateBalanceInfo();
            }).catch(e => {
                toast.error('Set Value Error', { autoClose: 1000 })
            })
        }
    }

    const handleMaxTermSavingsCount = (value, toggle) => {
        if (!value) {
            toast.warning('Please Input Positive value', { autoClose: 1000 });
        } else {
            setBalanceInfoAPI({ max_activity_count: value }).then(res => {
                toggle && toggle();
                toast.success(res.message, { autoClose: 1000 });
                updateBalanceInfo();
            }).catch(e => {
                toast.error('Set Value Error', { autoClose: 1000 })
            })
        }
    }

    const WidgetData = {
        data1: {
            title: 'Interest rate',
            color: 'info',
            mainColor: '#59CFEB',
            icon: 'top-interests-rate',
        },
        data2: {
            title: 'Refferal Bonus',
            color: 'danger',
            mainColor: '#FF5F55',
            icon: 'top-bonus',
        },
        data3: {
            title: 'Referral Threshold Amount',
            color: 'secondary',
            mainColor: '#FF37B1',
            icon: 'top-customers',
        },
        data4: {
            title: 'Term Savings Threshold Amount',
            color: 'primary',
            mainColor: '#5167F1',
            icon: 'top-savings',
        },
        data5: {
            title: 'Max Term Savings Count',
            color: 'warning',
            mainColor: '#F3C568',
            icon: 'top-staff',
        },
    }

    const [balanceInfo, setBalanceInfo] = useState({
        rate: 0,
        refferalBonus: 0,
        min_refferal_bonus_fix_amount: 0,
    });

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        updateBalanceInfo();
    }, []);

    const updateBalanceInfo = () => {
        setIsLoading(true);
        getBalanceInfoAPI().then(res => {
            setIsLoading(false);
            setBalanceInfo(res.body);
        }).catch(() => {
            setIsLoading(false);
        });
    }

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Financial Settings' />
            <Container fluid={true}>
                <Row className='widget-grid'>
                    <Col xxl={3} xs='4'>
                        <Widget
                            data={WidgetData.data1}
                            value={formatPercentage(balanceInfo.rate)}
                            handleClick={handleSetInterestRate}
                            isLoading={isLoading}
                        />
                    </Col>
                    <Col xxl={3} xs='4'>
                        <Widget
                            data={WidgetData.data2}
                            value={formatCurrency(balanceInfo.refferalBonus)}
                            handleClick={handleSetRefferalBonus}
                            isLoading={isLoading}
                        />
                    </Col>
                    <Col xxl={3} xs='4'>
                        <Widget
                            data={WidgetData.data3}
                            value={formatCurrency(balanceInfo.min_refferal_bonus_fix_amount)}
                            handleClick={handleMinBonusRate}
                            isLoading={isLoading}
                        />
                    </Col>
                    <Col xxl={3} xs='4'>
                        <Widget
                            data={WidgetData.data4}
                            value={formatCurrency(balanceInfo.term_savings_threshold)}
                            handleClick={handleTermSavingsThreshold}
                            isLoading={isLoading}
                        />
                    </Col>
                    <Col xxl={3} xs='4'>
                        <Widget
                            data={WidgetData.data5}
                            value={balanceInfo.max_activity_count}
                            handleClick={handleMaxTermSavingsCount}
                            isLoading={isLoading}
                        />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default BalanceInfo;
