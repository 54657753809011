import React, { Fragment, useContext, useState } from "react";
import { Row, Col, Modal, ModalHeader, Form, FormGroup, Label, Input, CardBody, Button, CardFooter, ModalBody } from 'reactstrap'
import { toast } from "react-toastify";
import { Btn, H4 } from '../../../../AbstractElements';
import { createDepartmentAPI } from "../../../../Library/apis";
import UserContext from "../../../../_helper/User";

const AddDepartmentsModal = ({ modal, toggle }) => {

    const { updateDepartments } = useContext(UserContext);

    const [formValues, setFormValues] = useState({ name: "", });

    const onEditSubmit = () => {

        if (!formValues.name) return;

        createDepartmentAPI({ name: formValues.name }).then(res => {
            toast.success(res.message, { autoClose: 1000 });
            setFormValues({ name: "", roles: [], });
            updateDepartments();
            toggle();
        }).catch(error => {
            const message = error?.response?.data?.message || 'Add Staff Role Error';
            toast.error(message, { autoClose: 1000 });
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    return (
        <Modal isOpen={modal} toggle={toggle} centered className="font-outfit">
            <ModalHeader toggle={toggle}>
                <H4>{'Add New Department'}</H4>
            </ModalHeader>
            <ModalBody>
                <FormGroup className="mb-3">
                    <Label className="form-label">{'Department Name'}</Label>
                    <Input
                        className="form-control"
                        type="text"
                        placeholder="Department Name *"
                        name="name"
                        required
                        value={formValues.name || ""}
                        onChange={handleChange}
                    />
                </FormGroup>
                <div className="text-end">
                    <Button color="primary" onClick={onEditSubmit}>{'Create'}</Button>
                </div>
            </ModalBody>
        </Modal >
    );
};

export default AddDepartmentsModal;
