import React from 'react';
import { Row } from 'reactstrap';
import Fade from 'react-reveal/Fade';

import { H1 } from '../../../../AbstractElements';
import TopHeader from '../TopHeader';
import Footer from '../Footer';

import landing_bg from '../../../../assets/images/landing/landing_top_banner.jpg';

import '../index.css';

const TermsPage = () => {

    const sessionData = [
        {
            title: "Terms of Use",
            subTitle: "Introduction",
            texts: [
                "These Terms are to be read in conjunction with any terms, conditions, or disclaimers provided on the pages of our HR survey system website. By accessing and using this website, including its survey tools and resources, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms or our Privacy Policy, you are not authorized to access our website, use any of its services, or submit any information."
            ],
        },
        {
            subTitle: "Use of our Website",
            texts: [
                "You agree to use our HR survey system website only for lawful purposes related to human resource management, employee feedback, and related activities. You must not use our website for any activity that constitutes a civil or criminal offense or violates any law. Additionally, you agree not to interfere with our website’s network or security features or to gain unauthorized access to our systems."
            ],
        },
        {
            subTitle: "General Conditions",
            texts: [
                "We reserve the right to modify, suspend, or discontinue any aspect of our website, including any survey tool, service, content, feature, or product, at any time. We will not be liable to you or any third party for any such modification, suspension, or discontinuance."
            ],
        },
        {
            subTitle: "Survey Services",
            texts: [
                "Our survey services are subject to availability and may change from time to time. We reserve the right, in our sole discretion, to limit or cancel the services offered on our website. This limitation or cancellation may apply to certain individuals, households, geographic regions, or jurisdictions, as determined by us."
            ],
        },
        {
            subTitle: "Data Use and Privacy",
            texts: [
                "All data collected through our survey services will be used in accordance with our Privacy Policy. We are committed to maintaining the confidentiality and security of all participant data and survey responses."
            ],
        },
    ]


    return (
        <div className="landing-page">
            <div className='first-section' style={{ backgroundImage: `url(${landing_bg})`, height: 120 }}>
                <TopHeader />
            </div>

            {sessionData.map(item => <Fade right>
                <Row className="about-section" style={{ margin: '40px' }}>
                    {item.title && <H1 attrH1={{ className: 'font40-bold font-outfit text-dark', style: { marginTop: '20px' } }}>{item.title}</H1>}
                    {item.subTitle && <H1 attrH1={{ className: 'font32-bold font-outfit text-dark', style: { marginTop: '20px' } }}>{item.subTitle}</H1>}
                    {item.texts.map(text => <H1 attrH1={{ className: 'font24_outfit text-dark', style: {} }}>{text}</H1>)}
                </Row>
            </Fade>)}

            <Footer />

        </div >
    );
};

export default TermsPage;
