import React, { useEffect, useState } from 'react';
import ChartjsContext from './index';

const ChartjsProvider = (props) => {
  const [chartjs, setChartjs] = useState([]);

  return (
    <ChartjsContext.Provider
      value={{
        ...props,
        chartjs,
      }}
    >
      {props.children}
    </ChartjsContext.Provider>
  );
};

export default ChartjsProvider;