import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Breadcrumbs, H1, H3, H5 } from '../../../AbstractElements';
import _ from 'lodash';
import { Container, Row, Card, CardBody, Col, Button, Toast } from 'reactstrap';
import './index.css';
import { getSurveyListAPI } from '../../../Library/apis';
import { useNavigate } from 'react-router';
import SurveyCard from './SurveyCard';
import { convertDate, convertDate2 } from '../../../Library/custom_utils';

const SurveyList = () => {

    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getSurveyListAPI().then((res) => {
            setData(res.body);
        })
    }, []);

    const handleGoDetails = (id) => {
        navigate(`/survey/survey-details/${id}`);
    }

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Survey History' />
            <Container fluid={true}>
                <Row>
                    {data.length && data.map((item, index) =>
                        <Col key={`surveycard_${index}`} xxl='3' xl={4} lg={6} sm={12} className='survey-button' onClick={() => { handleGoDetails(item._id) }}>
                            <SurveyCard
                                title={`Survey ${index + 1}`}
                                value={`Start Date: ${convertDate2(item.createdAt)}`}
                                type={item?.invites ? 'invite' : 'normal'}
                            />
                        </Col>
                    )}
                </Row>
            </Container >
        </Fragment >
    );
};

export default SurveyList;
