import React, { Fragment, useState, useRef, useContext } from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import { usePaystackPayment } from 'react-paystack';
import { toast } from 'react-toastify';

import { H3, LI, P, UL } from '../../../../AbstractElements';
import { Btn } from '../../../../AbstractElements';
import { COLORS } from '../../../../Library/theme';
import { useNavigate } from 'react-router';
import UserContext from '../../../../_helper/User';
import { formatCurrency } from './../../../../Library/custom_utils';

const StandardPricingcard = () => {

  const { userInfo } = useContext(UserContext);

  const config = {
    reference: (new Date()).getTime().toString(),
    email: userInfo.email,
    amount: 20000,
    publicKey: 'pk_test_bd6617ade26ca8c25e5794c1bfbbdf238ec49df1',
  };

  const initializePayment = usePaystackPayment(config);
  const [currentPlanIndex, setCurrentPlanIndex] = useState(0);
  const selectedIndex = useRef(0);
  const navigate = useNavigate();

  const pricingData = [
    {
      title: 'Free trial',
      priceValues: {
        amount: formatCurrency(0),
        duration: '/mo'
      },
      pricingContent: [
        '30 Days Free trial',
        '1 HR Admin',
        '2 Managers',
        '1 ~ 10 Employees'
      ],
      button: "Let's try"
    },
    {
      title: 'Standard',
      priceValues: {
        amount: formatCurrency(10000),
        duration: '/mo'
      },
      pricingContent: [
        '30 Days',
        '2 HR Admin',
        '1 ~ 5 Managers',
        '100 Employees'
      ],
      button: 'Upgrade'
    },
    {
      title: 'Premium',
      priceValues: {
        amount: formatCurrency(50000),
        duration: '/mo'
      },
      pricingContent: [
        '30 Days',
        '5 HR Admin',
        '10 Managers',
        '500 Employees'
      ],
      button: 'Upgrade'
    },
    {
      title: 'Business',
      priceValues: {
        amount: formatCurrency(100000),
        duration: '/mo'
      },
      pricingContent: [
        '30 Days',
        'No Limit HR Admin',
        'No Limit Managers',
        'No Limit Employees'
      ],
      button: 'Upgrade'
    },
  ];

  const onSuccess = (reference) => {
    console.log(selectedIndex.current, reference);
    setCurrentPlanIndex(selectedIndex.current);
    toast.success('Success', { autoClose: 1000 });
  };

  const onClose = () => {
    toast.success('Closed', { autoClose: 1000 });
  }

  const handlePay = () => {
    const jwt_token = localStorage.getItem('token');
    if (jwt_token) {
      initializePayment(onSuccess, onClose);
    } else {
      navigate('/login', { state: { type: 'payment' } });
    }
  }

  return (
    <Fragment>
      <CardBody>
        <Row className='pricing-block'>
          {pricingData.map((item, index) => (
            <Col lg='3' md='6'>
              <div className='pricingtable'>
                <div className='pricingtable-header'>
                  <H3 attrH3={{ className: 'title', style: { color: currentPlanIndex == index ? COLORS.success : COLORS.primary } }}>{item.title}</H3>
                </div>
                <div className={'font-outfit font40-bold mb-4'} style={{ color: COLORS.primary }}>
                  <span className='amount'>{item.priceValues.amount}</span>
                  <span className='duration font30'>{item.priceValues.duration}</span>
                </div>
                <UL attrUL={{ className: 'pricing-content flex-row' }}>
                  {item.pricingContent.map(text => (<LI attrLI={{ className: `border-0 ₦{currentPlanIndex == index ? 'success_active' : ''}` }}>{text}</LI>))}
                </UL>
                <div className='pricingtable-signup'>
                  <Btn attrBtn={{
                    color: `${currentPlanIndex !== index ? 'primary' : 'success'}`, size: 'lg',
                    disabled: index == 0,
                    onClick: () => {
                      selectedIndex.current = index;
                      handlePay();
                    }
                  }}>{currentPlanIndex == index ? 'Activated' : currentPlanIndex > index ? 'Downgrade' : item.button}</Btn>
                </div>
              </div>
            </Col>))}
        </Row>
      </CardBody>
    </Fragment>
  );
};
export default StandardPricingcard;
