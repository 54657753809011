import React, { Fragment } from 'react';
import { Row, Col, CardBody, Card } from 'reactstrap';
import { useNavigate } from 'react-router';

import { Btn, H3, LI, P, UL } from '../../../AbstractElements';
import { COLORS } from '../../../Library/theme';
import { formatCurrency } from '../../../Library/custom_utils';

const StandardPricingcard = () => {

  const navigate = useNavigate();

  const pricingData = [
    {
      title: 'Free trial',
      priceValues: {
        amount: formatCurrency(0),
        duration: '/mo'
      },
      pricingContent: [
        '30 Days Free trial',
        '1 HR Admin',
        '2 Managers',
        '1 ~ 10 Employees'
      ],
      button: "Let's try"
    },
    {
      title: 'Standard',
      priceValues: {
        amount: formatCurrency(10000),
        duration: '/mo'
      },
      pricingContent: [
        '30 Days',
        '2 HR Admin',
        '1 ~ 5 Managers',
        '100 Employees'
      ],
      button: 'Upgrade'
    },
    {
      title: 'Premium',
      priceValues: {
        amount: formatCurrency(50000),
        duration: '/mo'
      },
      pricingContent: [
        '30 Days',
        '5 HR Admin',
        '10 Managers',
        '500 Employees'
      ],
      button: 'Upgrade'
    },
    {
      title: 'Business',
      priceValues: {
        amount: formatCurrency(100000),
        duration: '/mo'
      },
      pricingContent: [
        '30 Days',
        'No Limit HR Admin',
        'No Limit Managers',
        'No Limit Employees'
      ],
      button: 'Upgrade'
    },
  ];

  const handleLogIn = () => {
    navigate('/login', { state: { type: 'payment' } });
  }

  return (
    <Fragment>
      <Row className='pricing-block'>
        {pricingData.map((item, index) => (
          <Col lg='3' md='6'>
            <Card className='m-3 survey-button' onClick={handleLogIn}>
              <div className='pricingtable'>
                <div className='pricingtable-header'>
                  <H3 attrH3={{ className: 'title', style: { color: COLORS.primary } }}>{item.title}</H3>
                </div>
                <div className={'font-outfit font50-bold mb-4'} style={{ color: COLORS.primary }}>
                  <span className='amount'>{item.priceValues.amount}</span>
                  <span className='duration font30'>{item.priceValues.duration}</span>
                </div>
                <UL attrUL={{ className: 'pricing-content flex-row' }}>
                  {item.pricingContent.map(text => (<LI attrLI={{ className: `border-0` }}>{text}</LI>))}
                </UL>
                <div className='pricingtable-signup'>
                  <Btn attrBtn={{ color: 'primary', size: 'lg' }}>{"Let's Try!"}</Btn>
                </div>
              </div>
            </Card>
          </Col>))}
      </Row>
    </Fragment >
  );
};
export default StandardPricingcard;
