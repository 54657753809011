import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import Widgets1 from './Widgets1';
import { H3 } from '../../../AbstractElements';
import { formatPercentage } from '../../../Library/custom_utils';

const TotalValueWidget = ({ data, isLoading }) => {

  const DATA = [
    {
      title: 'Invites',
      color: 'primary',
      mainColor: '#5167F1',
      icon: 'top-savings',
      value: data.inviteNumber
    },
    {
      title: 'Responses',
      color: 'danger',
      mainColor: '#FF5F55',
      icon: 'top-termsavings',
      value: data.responseNumber
    },
    {
      title: 'Response Rate',
      color: 'success',
      mainColor: '#22FF0E',
      icon: 'top-interests',
      value: `${data.responseRate}%`
    },
    {
      title: 'Happy Employees',
      color: 'secondary2',
      mainColor: '#D76AE5',
      icon: 'top-refferal',
      value: data.happyMembers
    },
  ]

  return (
    <Container fluid>
      <H3 attrH3={{ className: 'mb-3 f-light' }}>{'EMPLOYEE VIBE CHECK RESULTS'}</H3>
      <Row>
        {DATA.map((item, i) => <Col key={`walletInfo_${i}`} xxl='3' xl={4} lg={6} sm={12}>
          <Widgets1 data={item} isLoading={isLoading} />
        </Col>)}
      </Row>
    </Container>
  );
};

export default TotalValueWidget;
