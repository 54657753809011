import React from 'react';
import { Line } from 'react-chartjs-2';
import { lineChartOptions } from './chartjs';
import { CONSTANTS } from '../../../../Library/theme';

const LineChartClass = () => {

    const extendDataTo12Months = (data) => {
        while (data.length < 12) {
            data.push(...data.slice(Math.floor(Math.random() * 100), 12 - data.length));
        }
        return data.slice(0, 12);
    };

    const lineChartData = {
        labels: CONSTANTS.months,
        datasets: [
            {
                label: CONSTANTS.reactions[0],
                data: [0],
                borderColor: '#7C2283',
                backgroundColor: "#7C2283",
                borderWidth: 2,
            },
            {
                label: CONSTANTS.reactions[1],
                data: [0],
                borderColor: '#1288FA',
                backgroundColor: "#1288FA",
                borderWidth: 2,
            },
            {
                label: CONSTANTS.reactions[2],
                data: [0],
                borderColor: '#193098',
                backgroundColor: "#193098",
                borderWidth: 2,
            },
            {
                label: CONSTANTS.reactions[3],
                data: [0],
                borderColor: '#EE6E47',
                backgroundColor: "#EE6E47",
                borderWidth: 2,
            },
            {
                label: CONSTANTS.reactions[4],
                data: [0],
                borderColor: '#FF97E5',
                backgroundColor: "#FF97E5",
                borderWidth: 2,
            }
        ],
        plugins: {
            datalabels: {
                display: false,
                color: 'white'
            }
        }
    }

    return (
        <Line data={lineChartData} options={lineChartOptions} width={717} height={358} />
    )
}

export default LineChartClass;