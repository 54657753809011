import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarController,
    BarElement,
    ArcElement,
    RadialLinearScale
} from 'chart.js';
import { CONSTANTS } from '../../../../Library/theme';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarController,
    BarElement,
    ArcElement,
    RadialLinearScale
);

export const lineChartData = {
    labels: CONSTANTS.months,
    datasets: [
        {
            label: CONSTANTS.reactions[0],
            data: [15, 15, 15, 28, 21],
            borderColor: '#7C2283',
            backgroundColor: "#7C2283",
            borderWidth: 2,
        },
        {
            label: CONSTANTS.reactions[1],
            data: [20, 20, 20, 20, 15],
            borderColor: '#1288FA',
            backgroundColor: "#1288FA",
            borderWidth: 2,
        },
        {
            label: CONSTANTS.reactions[3],
            data: [30, 20, 15, 10, 15],
            borderColor: '#193098',
            backgroundColor: "#193098",
            borderWidth: 2,
        },
        {
            label: CONSTANTS.reactions[4],
            data: [30, 30, 50, 5, 30],
            borderColor: '#EE6E47',
            backgroundColor: "#EE6E47",
            borderWidth: 2,
        },
        {
            label: CONSTANTS.reactions[5],
            data: [30, 30, 50, 5, 30],
            borderColor: '#EE6E47',
            backgroundColor: "#EE6E47",
            borderWidth: 2,
        }
    ],
    plugins: {
        datalabels: {
            display: false,
            color: 'white'
        }
    }
}

export const lineChartOptions = {
    maintainAspectRatio: true,
    legend: {
        display: false,
    },
    scales: {
        xAxes: {
            stacked: false,
        },
        yAxes: {
            stacked: false,
        }
    },
    plugins: {
        datalabels: {
            display: false,
        }
    }
}