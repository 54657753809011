import React, { Fragment } from 'react'
import DataTable from 'react-data-table-component';

import { Badges, H3, H6 } from '../../../../AbstractElements';
import { TrendingDown, TrendingUp } from 'react-feather';
import { convertDateTime, formatCurrency } from '../../../../Library/custom_utils';
import SurveyLoader from '../../Common/SurveyLoader';

const TransactionTable = ({ data: defaultData, isLoading }) => {

  const tableColumns = [
    {
      name: 'User ID',
      selector: row => row['user'],
      width: '140px',
      center: false,
    },
    {
      name: 'Amount',
      selector: row => row['amount'],
      center: false,
    },
    {
      name: 'Date/Time',
      selector: row => row['startDate'],
      center: true,
    },
    {
      name: 'Bank Name',
      selector: row => row['bankName'],
      center: true,
    },
    {
      name: 'Bank Account',
      selector: row => row['accountNumber'],
      center: true,
    },
    {
      name: 'Wallet Balance',
      selector: row => row['totalAmount'],
      center: true,
    },
    {
      name: 'Status',
      selector: row => row['status'],
      center: true,
    },
  ];

  const renderItem = (item) => {

    const bankName = item?.details?.data?.sourceInformation?.bankName || item?.details?.bankName || item?.bankName || 'Access Bank';
    const accountNumber = item?.details?.data?.sourceInformation?.sourceAccountNumber || item?.details?.beneficiaryAccountNumber || '0000000000';
    const statusColor = item.status == 'Success' ? 'success' : item.status == 'Pending' ? 'info' : 'danger';

    return {
      user: <H6 attrH6={{ className: 'f-14 f-light' }}>{item.userId}</H6>,
      amount:
        <div className='d-flex'>
          {item.type == 'Withraw' ? <TrendingDown className='font-danger me-2' /> : <TrendingUp className='font-success me-2' />}
          <div>
            <H6 attrH6={{ className: 'f-14 mb-2' }}>{`${item.type == 'Withraw' ? 'Withdraw' : 'Deposit'}`}</H6>
            <H3 attrH3={{ className: 'f-14 mb-0 f-light' }}>{formatCurrency(item.amount)}</H3>
          </div>
        </div>,
      startDate: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{convertDateTime(item.createdAt)}</H6>,
      bankName: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{bankName}</H6>,
      accountNumber: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{accountNumber}</H6>,
      status: <Badges attrBadge={{ className: 'badge rounded-pill', color: statusColor, pill: true, style: { minWidth: 60 } }}>{item.status}</Badges>,
      totalAmount: <span className="font-success font18-bold">{formatCurrency(item.totalAmount)}</span>,
    }
  }

  return (
    <Fragment>
      {isLoading ? <SurveyLoader /> :
        <DataTable
          data={defaultData.map(item => renderItem(item))}
          columns={tableColumns}
          striped
          center
          paginationPerPage={20}
          pagination
          style={{ color: 'red' }}
        />}
    </Fragment>
  )
}
export default TransactionTable