import React, { useEffect, useState } from 'react';
import Context from './index';

const TaskProvider = (props) => {
    const [allTask, setAllTask] = useState([]);
    const [newTask, setNewTask] = useState([]);

    const AddNewTask = (data) => {
        const taskTemp = {
            id: allTask.length + 1,
            title: data.title,
            collection: data.collection,
            desc: data.desc,
        };
        setAllTask([...allTask, taskTemp]);
    };

    const RemoveTask = (id) => {
        setAllTask(allTask.filter((data) => data.id !== id));
    };

    return (
        <Context.Provider
            value={{
                ...props,
                allTask,
                newTask,
                AddNewTask: AddNewTask,
                RemoveTask: RemoveTask
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default TaskProvider;