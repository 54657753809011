import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { H4, H5 } from '../../../AbstractElements';
import SvgIcon from '../../Common/Component/SvgIcon';

const SurveyCard = ({ title, value, type }) => {

  let initialData = {
    title: title,
    color: 'secondary',
    mainColor: '#FF37B1',
    icon: 'top-customers',
    value: value
  };

  if (type == 'invite') {
    initialData = {
      title:  `${title} (Auto)`,
      color: 'success',
      mainColor: '#66FC5A',
      icon: 'top-interests',
      value: value
    };
  };

  return (
    <Card className='widget-1 position-relative' style={{borderRadius: 10}}>
      <div className='position-absolute' style={{ left: 'calc(50% - 75px)', fill: 'red' }}>
        <SvgIcon className='svg-fill' iconId={`top-border-${initialData.color}`} />
      </div>
      <CardBody style={{ padding: '16px' }}>
        <div className=''>
          <div className='d-flex align-items-center gap-2'>
            <div className='d-flex align-items-center justify-content-center' style={{ width: '30px', height: '30px', background: `${initialData.mainColor}27`, borderRadius: '5px' }}>
              <SvgIcon className='svg-fill' iconId={`${initialData.icon}`} style={{ width: '20px', height: '20px' }} />
            </div>
            <span className='text-muted font18'>{initialData.title}</span>
          </div>
          <H4 attrH4={{ className: 'text-muted font16 mt-2 mb-2 ms-4' }}>{initialData.value}</H4>
        </div>
      </CardBody>
    </Card >
  );
};

export default SurveyCard;
