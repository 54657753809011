
const homeMenu = { path: '/survey/survey-overview', title: 'Survey Overview', icon: 'bx_home', type: 'link' };
const surveyYTDOverView = { path: '/survey/ytd-overview', title: 'YTD Overview', icon: 'bx_report', type: 'link' };
const surveyDetails = { path: '/survey/survey-details', title: 'Survey Details', icon: 'bx_report', type: 'link' };

const surveyManagementMenu_Create = { path: '/survey/survey-management/create-survey', title: 'Create Survey', type: 'link' };
const surveyManagementMenu_List = { path: '/survey/survey-management/survey-history', title: 'Survey History', icon: 'bx_report', type: 'link' };

const staffSubMenu = { path: '/survey/staff-management/administrators', title: 'Administrators', type: 'link' };
const departmentSubMenu = { path: '/survey/staff-management/departments', title: 'Departments', type: 'link' };
const managerSubMenu = { path: '/survey/staff-management/managers', title: 'Managers', type: 'link' };

const pricingMenu = { path: '/survey/account-settings/pricing', title: 'Pricing', icon: 'bx_profile', type: 'link' };
const profileMenu = { path: '/survey/account-settings/profile', title: 'Profile', icon: 'bx_profile', type: 'link' };

const filterMenuItemsByRightRole = (roles) => {
  let items = [homeMenu];

  if (roles.includes('Manage Survey') || roles.includes('View Survey')) items.push(surveyYTDOverView);
  if (roles.includes('Manage Survey') || roles.includes('View Survey')) items.push(surveyDetails);

  let surveyManagementItem = [];
  if (roles.includes('Manage Survey')) surveyManagementItem.push(surveyManagementMenu_Create)
  if (roles.includes('Manage Survey')) surveyManagementItem.push(surveyManagementMenu_List)

  const surveyManagementMenu = {
    title: 'Survey Management',
    icon: 'bx_report',
    type: 'sub',
    active: false,
    children: surveyManagementItem
  };

  if (surveyManagementItem.length > 0) items.push(surveyManagementMenu);

  let staffItem = [];
  if (roles.includes('View Staff') || roles.includes('Add/Update Staff')) staffItem.push(staffSubMenu)
  if (roles.includes('View Manager') || roles.includes('Add/Update Manager')) staffItem.push(managerSubMenu)
  if (roles.includes('Add/Update Department')) staffItem.push(departmentSubMenu)

  const staffManagementMenu = {
    title: 'Staff Management',
    icon: 'bx_staff',
    type: 'sub',
    active: false,
    children: staffItem,
  }
  if (staffItem.length > 0) items.push(staffManagementMenu);

  if (roles.includes('Manage Survey')) items.push(pricingMenu);
  items.push(profileMenu);
  return items;
}

const LIVE_MENUITEMS = (roles) => {
  return [{
    menucontent: 'Dashboards,Widgets',
    Items: filterMenuItemsByRightRole(roles),
  }];
}

export const MENUITEMS = (roles) => LIVE_MENUITEMS(roles);