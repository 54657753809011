import React, { Fragment, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, Col } from 'reactstrap';
import HeaderCard from '../../../Common/Component/HeaderCard';
import { COLORS, CONSTANTS } from '../../../../Library/theme';

const options = {
    chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
            show: false
        },
        zoom: {
            enabled: true
        }
    },
    colors: COLORS.chartColors,
    responsive: [{
        breakpoint: 480,
        options: {
            legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
            }
        }
    }],
    plotOptions: {
        bar: {
            horizontal: false,
            borderRadius: 0,
            columnWidth: '40%',
            dataLabels: {
                total: {
                    enabled: true,
                    style: {
                        fontSize: '13px',
                        fontWeight: 900
                    }
                }
            }
        },
    },
    xaxis: {
        type: 'text',
        categories: CONSTANTS.workStationData.map(item => item.value),
    },
    legend: {
        position: 'right',
        offsetY: 20
    },
    fill: {
        opacity: 1
    }
}

const VerticalStackBar = ({ data }) => {

    const getSeriesData = (index, isInit = false) => {
        const row = data[index];

        const result = CONSTANTS.workStationData.map((element) => {
            if (!row?.hasOwnProperty(element.key)) {
                return 0;
            }
            return isInit ? 0 : row?.[element.key] || 0;
        });
        return result;
    };

    const categories = ['Happy', 'Smiley', 'Indifferent', 'Worried', 'Angry'];
    function generateChartData(isInit = false) {
        return categories.map((category, index) => ({
            name: category,
            data: getSeriesData(4 - index, isInit)
        }));
    }

    const [chartData, setChartData] = useState(generateChartData(true));

    useEffect(() => {
        if (data) {
            setChartData(generateChartData(false));
        }
    }, [data])

    return (
        <Fragment>
            <Col sm='12'>
                <Card>
                    <HeaderCard title={'Stack Bar'} />
                    <CardBody>
                        <ReactApexChart options={options} series={chartData} type="bar" height={300} />
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
}

export default VerticalStackBar;
