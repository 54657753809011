import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
import _ from 'lodash';

import { Breadcrumbs } from '../../../../AbstractElements';
import './index.css';
import DepartmentsTable from './DepartmentsTable';

const Departments = () => {

    return (
        <Fragment>
            <Breadcrumbs mainTitle='All Staff'/>
            <Container fluid={true}>
                <DepartmentsTable />
            </Container>
        </Fragment >
    );
};

export default Departments;
