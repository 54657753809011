import React, { Fragment, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import ReactInputVerificationCode from 'react-input-verification-code';
import { Btn, H4 } from '../../../../AbstractElements';
import { activeUserAPI, loginAPI, sendVerificationRequestAPI } from '../../../../Library/apis';
import { toast } from 'react-toastify';
import { COLORS } from '../../../../Library/theme';
import UserContext from '../../../../_helper/User';
import SurveyLogo from '../../Common/SurveyLogo';

const CodeVerification = () => {

  const { setUserInfo, socket } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');

  const state = location.state;
  const formData = state?.formData;

  const handleLogIn = async () => {

    setLoading(true);
    try {
      const data = await loginAPI({ ...formData, socketId: socket.id, code });
      data?.body?.userInfo && setUserInfo(data?.body?.userInfo);
      localStorage.setItem('authenticated', true);
      localStorage.setItem('login', true);
      localStorage.setItem('userInfo', JSON.stringify(data?.body?.userInfo));
      localStorage.setItem('token', data?.body?.token);
      navigate(`/mothrift/home`)
    } catch (error) {
      console.log(error);
      console.log(error?.response?.data);
      toast.error(error?.response?.data?.message || 'Some thing went wrong on the login', { autoClose: 1000 })
    }
    setLoading(false);
  }

  const handleActive = () => {
    setLoading(true);
    const data = { userInfo: formData?.email || formData?.phoneNumber, activationCode: code };
    activeUserAPI(data).then(res => {
      toast.success('Successfully Activated!', { autoClose: 1000 })
      navigate('/login');
      setLoading(false);
    }).catch(error => {
      console.log(error?.response?.data);
      toast.error(error?.response?.data?.message || 'Some thing else may be validation error', { autoClose: 1000 })
      setLoading(false);
    });
  }

  const handleReset = () => {
    sendVerificationRequestAPI({ userInfo: formData.userInfo }).then(() => {
      toast.success('Verification code sent again.', { autoClose: 1000 });
    }).catch(e => console.log(e));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state?.type == 'login') handleLogIn();
    else handleActive();
  }

  return (
    <Fragment>
      <section>
        <SurveyLogo />
        <div className='login-card'>
          <Card className='p-5 m-4' style={{ width: '500px' }}>
            <Form className='theme-form login-form' onSubmit={handleSubmit}>
              <div className='d-flex'>
                <Btn attrBtn={{ className: 'p-0 m-0', color: 'transparent', style: { borderWidth: 0 }, type: 'submit', onClick: () => { navigate('/login'); } }}>
                  <i className="fa fa-angle-left" style={{ color: COLORS.primary, fontSize: 35, fontWeight: 100 }}></i>
                </Btn>
                <H4 attrH4={{ className: 'text-secondary pt-2 ps-3' }}>Verify Your Account</H4>
              </div>
              <FormGroup>
                <Label className='m-0 col-form-label w-100'>A one-time passcode was sent to you via email and SMS</Label>
                <Label className='m-0 col-form-label w-100'>Please enter the 6 digit OTP below</Label>
                <Label className='m-0 col-form-label'>Enter OTP here</Label>
                <div className="passcode-container">
                  <ReactInputVerificationCode
                    length={6}
                    autoFocus
                    placeholder=""
                    onChange={setCode}
                  />
                </div>
              </FormGroup>
              <FormGroup className='mb-4 mt-4'>
                <span className='reset-password-link'>
                  Did not receive OTP?
                  <Btn attrBtn={{ color: 'transparent text-primary', onClick: handleReset }}>Resend</Btn>
                </span>
              </FormGroup>
              <FormGroup className='w-100'>
                <Btn attrBtn={{ className: 'w-100', color: 'primary', type: 'submit' }}>
                  {loading ? (<><Spinner size="sm" color="light" />{' Loading...'}</>) : ("Verify")}
                </Btn>
              </FormGroup>
            </Form>
          </Card>
        </div>
      </section>
    </Fragment >
  );
};

export default CodeVerification;
