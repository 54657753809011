import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import Avatar from 'react-avatar';
import { Row, Col, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap'
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import { H4, H5 } from "../../../../AbstractElements";
import { EditProfile } from '../../../../Constant';
import UserContext from "../../../../_helper/User";
import { changeAdminPasswordAPI, updateProfileAPI, } from "../../../../Library/apis";
import axios from 'axios';
import { SOCKET_URL } from "../../../../Library/constant";

const EditMyProfile = () => {

    const { userInfo, updateUserInfo } = useContext(UserContext);
    const { handleSubmit } = useForm();
    const [isEditPassWord, setIsEditPassword] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const fileInputRef = useRef();

    const [passwordValues, setPasswordValues] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    })

    const [formValues, setFormValues] = useState(userInfo);

    const onEditSubmit = () => {
        console.log(formValues)
        updateProfileAPI(formValues).then((res) => {
            toast.success(res.message, { autoClose: 1000 })
            updateUserInfo();
        }).catch((error) => {
            const message = error?.response?.data?.message || 'Update Profile Error'
            toast.error(message, { autoClose: 1000 });
        });
    }

    const handleEditPassword = () => {
        if (isEditPassWord) {
            if (!passwordValues.oldPassword) toast.warning('Old Password is empty.', { autoClose: 1000 })
            else if (!passwordValues.newPassword) toast.warning('New Password is empty.', { autoClose: 1000 })
            else if (!passwordValues.confirmPassword) toast.warning('Confirm Password is empty.', { autoClose: 1000 })
            else if (passwordValues.newPassword !== passwordValues.confirmPassword) toast.warning('Password is not match', { autoClose: 1000 })
            else {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Change it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        setIsloading(true);
                        changeAdminPasswordAPI({ oldPassword: passwordValues.oldPassword, newPassword: passwordValues.newPassword }).then((res) => {
                            toast.success(res.message, { autoClose: 1000 })
                        }).catch(e => {
                            toast.error('Password Change Error!', { autoClose: 1000 })
                        }).finally(() => setIsloading(false))
                    }
                })
            }
        } else {
            setIsEditPassword(true);
        }
    }

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormValues(({ ...formValues, [name]: value }));
    };

    const handlePasswordChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setPasswordValues(({ ...passwordValues, [name]: value }));
    };

    const [profileImageUrl, setProfileImageUrl] = useState(userInfo.profileImageUrl);

    const handleAvatarClick = () => {
        fileInputRef.current.click();
    };

    const readUrl = (event) => {
        if (event.target.files.length === 0) return;
        var mimeType = event.target.files[0].type;

        if (mimeType.match(/image\/*/) == null) {
            return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = async (_event) => {
            setProfileImageUrl(reader.result);

            const formData = new FormData();
            formData.append('image', event.target.files[0], '1.png');
            try {
                let token = localStorage.getItem('token');
                const headers = {
                    'Authorization': `Bearer ${token}` || '',
                    'Content-Type': 'multipart/form-data',
                }
                const response = await axios.post(`${SOCKET_URL}/admin_upload`, formData, { headers });
                setFormValues(({ ...formValues, profileImageUrl: `${SOCKET_URL}\\api\\${response.data.filePath}` }));
            } catch (error) {
                console.log(error)
                toast.error('upload error')
            }
        };
    };

    useEffect(() => {
        if (userInfo) {
            setFormValues({
                firstName: userInfo.firstName || "",
                middleName: userInfo.middleName || "",
                lastName: userInfo.lastName || "",
                phoneNumber: userInfo.phoneNumber || "",
                profileImageUrl: userInfo.profileImageUrl || "",
                email: userInfo.email || "",
            })
        }
    }, [userInfo])

    return (
        <Fragment>
            <Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
                <CardBody>
                    <Row>
                        <Row className='mb-2'>
                            <div className='profile-title'>
                                <div className='media'>
                                    <Avatar
                                        name={userInfo.userName}
                                        size="130"
                                        round
                                        color="#BF04CD"
                                        src={profileImageUrl || formValues.profileImageUrl}
                                        onClick={handleAvatarClick}
                                        style={{ cursor: 'pointer' }}
                                    />
                                    <input
                                        ref={fileInputRef}
                                        className='upload'
                                        style={{ display: 'none' }}
                                        type='file'
                                        onChange={(e) => readUrl(e)}
                                    />
                                    <div className='media-body'>
                                        <H4 attrH4={{ className: 'font30' }}>{userInfo.userName || '--'}</H4>
                                        <H4 attrH4={{ className: 'f-light' }}>{userInfo.positionName || 'Staff'}</H4>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Col md="5" className="p-4">
                            <FormGroup style={{ maxWidth: '350px' }}>
                                <Label className="form-label">{'First Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="First Name"
                                    name="firstName"
                                    required
                                    value={formValues.firstName || ""}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup style={{ maxWidth: '350px' }}>
                                <Label className="form-label">{'Middle Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    value={formValues.middleName || ""}
                                    name="middleName"
                                    onChange={handleChange}
                                    placeholder="Middle Name"
                                />
                            </FormGroup>
                            <FormGroup style={{ maxWidth: '350px' }}> <Label className="form-label">{'Sure Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    value={formValues.lastName || ""}
                                    name="lastName"
                                    onChange={handleChange}
                                    placeholder="Sure Name"
                                    required
                                />
                            </FormGroup>
                            <FormGroup style={{ maxWidth: '350px' }}><Label className="form-label">{'Email Address'}</Label>
                                <Input
                                    className="form-control"
                                    type="email"
                                    value={formValues.email || ""}
                                    onChange={handleChange}
                                    name="email"
                                    placeholder="Email Address"
                                    required
                                />
                            </FormGroup>
                            <FormGroup style={{ maxWidth: '350px' }}><Label className="form-label">{'Phone Number'}</Label>
                                <Input
                                    className="form-control"
                                    type="phone"
                                    value={formValues.phoneNumber || ""}
                                    onChange={handleChange}
                                    name="phoneNumber"
                                    placeholder="Phone Number"
                                    required />
                            </FormGroup>
                            <Button color="primary" type="submit">
                                {isUpdating && <Spinner size="sm" color="light" className="me-2" />}{'Update Profile'}
                            </Button>
                        </Col>

                        <Col md="2" className="p-4">
                            <div style={{ height: '100%', width: '1px', background: '#e4e4e4' }}> </div>
                        </Col>

                        <Col md="5" className="p-4">
                            <FormGroup style={{ maxWidth: '350px' }} className='mb-3'><Label className='form-label'>{'Old Password'}</Label>
                                <Input className='form-control' type='password' name="oldPassword" value={passwordValues.oldPassword} onChange={handlePasswordChange} />
                            </FormGroup>
                            <FormGroup style={{ maxWidth: '350px' }} className='mb-3'><Label className='form-label'>{'New Password'}</Label>
                                <Input className='form-control' type='password' name="newPassword" value={passwordValues.newPassword} onChange={handlePasswordChange} />
                            </FormGroup>
                            <FormGroup style={{ maxWidth: '350px' }} className='mb-3'><Label className='form-label'>{'Confirm Password'}</Label>
                                <Input className='form-control' type='password' name="confirmPassword" value={passwordValues.confirmPassword} onChange={handlePasswordChange} />
                            </FormGroup>
                            <Button color="primary" onClick={handleEditPassword} className="me-3">
                                {isLoading ? (<><Spinner size="sm" color="light" className="me-2" />{' Loading...'}</>) : ('Change Password')}
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Form>
        </Fragment >
    )
}
export default EditMyProfile