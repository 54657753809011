import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogIn, User } from 'react-feather';
import Avatar from 'react-avatar';
import { LI, UL, P } from '../../../AbstractElements';
import { Account, LogOut } from '../../../Constant';
import UserContext from '../../../_helper/User';

const UserHeader = () => {
  const history = useNavigate();

  const Logout = () => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('token');
    history(`${process.env.PUBLIC_URL}/login`);
  };

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  const { userInfo } = useContext(UserContext);

  return (
    <li className='profile-nav onhover-dropdown pe-0 py-0'>
      <div className='media profile-media'>
        <Avatar color="#BF04CD" name={userInfo.userName || userInfo.firstName +  ' ' + userInfo.lastName} size="40" round src={userInfo.profileImageUrl} textMarginRatio={0.2} />
        <div className='media-body'>
          <span>{userInfo.userName || userInfo.firstName +  ' ' + userInfo.lastName}</span>
          <P attrPara={{ className: 'mb-0 font-roboto' }}>
            {userInfo.positionName || 'Staff'}
          </P>
        </div>
      </div>
      <UL attrUL={{ className: 'simple-list profile-dropdown onhover-show-div' }}>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/survey/account-settings/profile`),
          }}>
          <User />
          <span>{Account} </span>
        </LI>
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{LogOut}</span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
