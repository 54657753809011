// dashbaord
import Home from '../Components/Survey/Home';
import EditProfile from '../Components/Survey/AccountSettings/EditProfile';
import BalanceInfo from '../Components/Survey/BalanceInfo';
import Customers from '../Components/Survey/ReportsAndRecords/Customers';
import BankTransfers from '../Components/Survey/ReportsAndRecords/BankTransfers';
import Savings from '../Components/Survey/ReportsAndRecords/Savings';
import TermSavings from '../Components/Survey/ReportsAndRecords/TermSavings';
import Interests from '../Components/Survey/ReportsAndRecords/Interests';
import CustomerManagement from '../Components/Survey/CustomerManagement';
import CustomerNotifications from '../Components/Survey/Notifications/CustomerNotifications';
import AdminNotifications from '../Components/Survey/Notifications/AdminNotifications';
import AdminSettings from '../Components/Survey/AdminSettings';
import AllStaff from '../Components/Survey/StaffMagement/AllStaff';
import StaffPositions from '../Components/Survey/StaffMagement/StaffPositions';
import Departments from '../Components/Survey/StaffMagement/Departments';
import AllManagers from '../Components/Survey/StaffMagement/AllManagers';
import SurveyManagement from '../Components/Survey/SurveyManagement';
import SurveyList from '../Components/Survey/SurveyList';
import SurveyDetails from '../Components/Survey/SurveyDetails';
import PricingMembershipContain from '../Components/Survey/AccountSettings/PricingMemberShip';
import SurveyByDepartments from '../Components/Survey/SurveyByDepartments';
import SurveyManagementDetails from '../Components/Survey/SurveyManagementDetails';

const SurveyRoutes = [
  { path: '/survey/survey-overview', Component: <Home /> },
  { path: '/survey/notifications/customer-notifications', Component: <CustomerNotifications /> },
  { path: '/survey/notifications/admin-notifications', Component: <AdminNotifications /> },
  { path: '/survey/financial-settings', Component: <BalanceInfo /> },
  { path: '/survey/reports-records/customers', Component: <Customers /> },
  { path: '/survey/reports-records/bank-transfers', Component: <BankTransfers /> },
  { path: '/survey/reports-records/bank-deposits', Component: <Savings /> },
  { path: '/survey/reports-records/term-savings', Component: <TermSavings /> },
  { path: '/survey/reports-records/interests', Component: <Interests /> },
  { path: '/survey/customer-management', Component: <CustomerManagement /> },
  
  { path: '/survey/ytd-overview', Component: <SurveyByDepartments /> },
  { path: '/survey/survey-details', Component: <SurveyManagementDetails /> },

  { path: '/survey/survey-management/create-survey', Component: <SurveyManagement /> },
  { path: '/survey/survey-management/survey-history', Component: <SurveyList /> },
  
  { path: '/survey/survey-details/:id', Component: <SurveyDetails /> },
  { path: '/survey/staff-management/administrators', Component: <AllStaff /> },
  { path: '/survey/staff-management/staff-positions', Component: <StaffPositions /> },
  { path: '/survey/staff-management/departments', Component: <Departments /> },
  { path: '/survey/staff-management/managers', Component: <AllManagers /> },
  { path: '/survey/account-settings/pricing', Component: <PricingMembershipContain /> },
  // { path: '/survey/account-settings/pricing', Component: <pricing /> },
  { path: '/survey/account-settings/profile', Component: <EditProfile /> },
  { path: '/survey/admin-settings', Component: <AdminSettings /> },
];

export const routes = SurveyRoutes;
