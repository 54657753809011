import React from 'react';
import { Row } from 'reactstrap';
import Fade from 'react-reveal/Fade';

import { H1 } from '../../../../AbstractElements';
import TopHeader from '../TopHeader';
import Footer from '../Footer';

import landing_bg from '../../../../assets/images/landing/landing_top_banner.jpg';

import '../index.css';

const PrivacyPage = () => {

    const sessionData = [
        {
            title: "Privacy Policy",
            subTitle: "Information We Collect",
            texts: [
                "- Personal Information: We collect personal information such as your name, email address, and job title that you provide when registering for our HR survey system.",
                "- Survey Responses: We collect responses to surveys, which may include personal opinions, feedback, and other data related to your employment or role within your organization.",
                "- Usage Data: We collect information on how you interact with our survey system, including survey completion rates, areas of interaction on our website, and preferences."
            ],
        },
        {
            subTitle: "How Your Information Is Collected",
            texts: [
                "- Directly from You: We collect information that you provide when you register on our website, respond to surveys, or interact with our customer service.",
                "- Automatically: We collect certain data automatically when you use our website, such as IP address, browser type, and operating system, to improve our service and user experience."
            ],
        },
        {
            subTitle: "Use of Your Information",
            texts: [
                "- To Provide Services: Your information is used to administer surveys, analyze responses, and provide insights relevant to HR management.",
                "- To Improve Our Services: We use your feedback and usage data to enhance our survey system and tailor it to user needs.",
                "- For Communication: We may use your contact information to send updates about our services or respond to your inquiries."
            ],
        },
        {
            subTitle: "Data Security and Privacy",
            texts: [
                "- We are committed to protecting the security of your personal information and employ various security measures to prevent unauthorized access or disclosure.",
                "- Your survey responses are confidential and will be used only for the purposes described in this policy."
            ],
        },
    ]

    return (
        <div className="landing-page">
            <div className='first-section' style={{ backgroundImage: `url(${landing_bg})`, height: 120 }}>
                <TopHeader />
            </div>

            {sessionData.map(item => <Fade right>
                <Row className="about-section" style={{ margin: '40px' }}>
                    {item.title && <H1 attrH1={{ className: 'font40-bold font-outfit text-dark', style: { marginTop: '20px' } }}>{item.title}</H1>}
                    {item.subTitle && <H1 attrH1={{ className: 'font32-bold font-outfit text-dark', style: { marginTop: '20px' } }}>{item.subTitle}</H1>}
                    {item.texts.map(text => <H1 attrH1={{ className: 'font24_outfit text-dark', style: {} }}>{text}</H1>)}
                </Row>
            </Fade>)}

            <Footer />

        </div >
    );
};

export default PrivacyPage;
