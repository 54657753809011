import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Modal, ModalHeader, Form, FormGroup, Label, Input, Spinner, Button } from 'reactstrap'
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import UserContext from "../../../../_helper/User";

const animatedComponents = makeAnimated();

const ViewStaffsModal = ({ modal, toggle, item }) => {

    const { emptyDepartments } = useContext(UserContext);

    const [formValues, setFormValues] = useState({
        staffId: '',
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        departments: [],
        profileImageUrl: ""
    });

    useEffect(() => {
        if (item) {
            setFormValues({
                staffId: item._id || '',
                firstName: item?.userInfo.firstName || "",
                middleName: item?.userInfo.middleName || "",
                lastName: item?.userInfo.lastName || "",
                phoneNumber: item.phoneNumber || "",
                profileImageUrl: item.profileImageUrl || "",
                email: item.email || "",
                departments: item.departments.length > 0 && item.departments[0].value ? item.departments : [],
            })
        }
    }, [item])

    return (
        <Modal isOpen={modal} toggle={toggle} centered size={'lg'} className="font-outfit">
            <ModalHeader toggle={toggle} >{'Staff Information'}</ModalHeader>
            <div className='d-flex flex-direction-column' style={{ flexDirection: 'column', gap: 20, marginLeft: 30, marginRight: 30, marginBottom: 30 }}>
                <Form>
                    <Row>
                        <Col md="4">
                            <FormGroup className="mb-3">
                                <Label className="form-label">{'First Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="First Name"
                                    name="firstName"
                                    required
                                    value={formValues.firstName || ""}
                                    disabled={true}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup> <Label className="form-label">{'Sure Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    value={formValues.lastName || ""}
                                    name="lastName"
                                    placeholder="Last Name"
                                    disabled={true}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup><Label className="form-label">{'Email Address'}</Label>
                                <Input
                                    className="form-control"
                                    type="email"
                                    value={formValues.email || ""}
                                    name="email"
                                    placeholder="Email Address"
                                    disabled={true}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup><Label className="form-label">{'Phone Number'}</Label>
                                <Input
                                    className="form-control"
                                    type="phone"
                                    value={formValues.phoneNumber || ""}
                                    name="phoneNumber"
                                    placeholder="Phone Number"
                                    disabled={true}
                                    required />
                            </FormGroup>
                        </Col>
                        <Col sm="12" md="8">
                            <FormGroup><Label className="form-label">{'Staff Position'}</Label>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    options={emptyDepartments}
                                    className="js-example-basic-single col-sm-12"
                                    isMulti
                                    disabled={true || item?.superAdmin}
                                    value={formValues?.departments}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <div className="text-end">
                    <Button color="primary" className="me-2" onClick={toggle}>
                        {'Close'}
                    </Button>
                </div>
            </div>
        </Modal >
    );
};

export default ViewStaffsModal;
