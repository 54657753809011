import React, { useContext, useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Input, Spinner } from 'reactstrap';
import { FaBan, FaCheck, FaFileImport, FaHistory, FaPlus } from 'react-icons/fa';
import { Edit, Trash2 } from 'react-feather';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import UserContext from '../../../_helper/User';
import { H6 } from '../../../AbstractElements';
import { COLORS, CONSTANTS } from '../../../Library/theme';
import { getTempEmployeeListAPI, sendSurveyAPI, setTempEmployeeListAPI } from '../../../Library/apis';

function isValidLocation(locationName = '') {
    return CONSTANTS.locatons.some(location => location.toLowerCase() === locationName.toLowerCase());
}

function ExcelReader() {
    const [items, setItems] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const inputRef = useRef();
    const [editRows, setEditRows] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { departments, userInfo } = useContext(UserContext);

    function isSameEmailDomain(email) {
        if (!userInfo.email || typeof userInfo.email !== 'string' || !email || typeof email !== 'string') {
            return false;
        }
        const userInfoEmailDomain = userInfo.email.split('@')[1]?.toLowerCase();
        const emailDomain = email.split('@')[1]?.toLowerCase();
        return userInfoEmailDomain === emailDomain;
    }

    function isDepartmentInList(departmentName) {
        return departments.some(department => department.name.toLowerCase() === departmentName.toLowerCase());
    }

    useEffect(() => {
        getTempEmployeeListAPI().then(res => {
            setItems(res.body.list || []);
            setIsFirst(false);
        }).catch((e) => {
            toast.error(e.response?.data?.message || 'Error', { autoClose: 1000 });
        })
    }, []);

    useEffect(() => {
        if (isFirst) return;
        items.length > 0 && setTempEmployeeListAPI({ list: items }).then(res => {
            // toast.success(res.message, { autoClose: 1000 })
        }).catch(e => {
            toast.error(e.response?.data?.message || 'Error', { autoClose: 1000 });
        })
    }, [items])

    const handleNewData = async (newData) => {
        let updatedItems = [...items];
        let overwriteAll = false;
        let skipAll = false;

        for (const newItem of newData) {
            const existingItem = updatedItems.find(item => item.email.toLowerCase() === newItem.email.toLowerCase());

            if (existingItem && !overwriteAll && !skipAll) {
                const result = await Swal.fire({
                    title: 'Duplicate Detected',
                    text: `An entry with email ${newItem.email} already exists.`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Replace One',
                    cancelButtonText: 'Skip All',
                    showDenyButton: true,
                    denyButtonText: 'Replace All',
                });

                if (result.isConfirmed) {
                    updatedItems = updatedItems.map(item => item.email.toLowerCase() === newItem.email.toLowerCase() ? newItem : item);
                } else if (result.isDenied) {
                    if (result.value === 'overwrite') {
                        overwriteAll = true;
                    } else {
                        skipAll = true;
                    }
                } else if (result.isDismissed) {
                    skipAll = true;
                }
            } else if (!existingItem || overwriteAll) {
                updatedItems.push(newItem);
            }
        }

        setItems(updatedItems.map(((item, index) => {
            return {
                ...item,
                id: index + 1,
            }
        })));
    };

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            let uniqueEmails = new Set();
            let duplicateCount = 0;
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: 'buffer' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                let data = XLSX.utils.sheet_to_json(ws);

                const filteredData = data.map((row, index) => {
                    let newRow = {};
                    let email = '';
                    let department = '';
                    let location = '';

                    Object.keys(row).forEach(key => {
                        if (key.toLowerCase().includes('email')) {
                            email = row[key];
                        }
                        if (key.toLowerCase() === 'department') {
                            department = row[key];
                        } else if (!department && key.toLowerCase().includes('department')) {
                            department = row[key];
                        }
                        if (key.toLowerCase() === 'location') {
                            location = row[key];
                        } else if (!department && (key.toLowerCase().includes('location') || key.toLowerCase().includes('state'))) {
                            location = row[key];
                        }
                        newRow[key] = row[key];
                    });

                    if (email && !uniqueEmails.has(email.toLowerCase())) {
                        uniqueEmails.add(email.toLowerCase());
                        newRow.email = email;
                        newRow.department = department;
                        newRow.location = location;
                        return newRow;
                    } else {
                        duplicateCount++;
                        return null;
                    }
                }).filter(item => item !== null);

                resolve({ filteredData, total: data.length, duplicateCount });
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then(({ filteredData }) => {
            handleNewData(filteredData);
        });
    };

    const handleImport = () => {
        inputRef.current.click();
    }

    const handleClear = () => {
        setItems([]);
        setTempEmployeeListAPI({ list: [] }).then(res => {
            toast.success(res.message, { autoClose: 1000 })
        }).catch(e => {
            toast.error(e.response?.data?.message || 'Error', { autoClose: 1000 });
        })
    }

    const handleAddOne = () => {
        const maxId = items.reduce((max, item) => Math.max(max, item.id), 0);
        const newId = maxId + 1;
        const newItem = { id: newId, email: '', department: '' };
        setItems([...items, newItem]);
        setEditRows({
            ...editRows,
            [newId]: { isEditing: true, tempData: { ...newItem } }
        });
    };

    const handleSend = () => {
        let invalidDepartmentCount = 0;
        let invalidEmailDomainCount = 0;

        items.forEach((item) => {
            if (!isDepartmentInList(item.department)) {
                invalidDepartmentCount++;
            }
            if (!isSameEmailDomain(item.email)) {
                invalidEmailDomainCount++;
            }
        });

        if (invalidDepartmentCount > 0 || invalidEmailDomainCount > 0) {
            let issueDetails = '';
            if (invalidDepartmentCount > 0) {
                issueDetails += `${invalidDepartmentCount} issue(s) with department. `;
            }
            if (invalidEmailDomainCount > 0) {
                issueDetails += `${invalidEmailDomainCount} issue(s) with email domain.`;
            }
            Swal.fire({
                title: 'Are you sure?',
                text: `There are issues with the data: ${issueDetails} Do you want to send anyway?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Send Anyway',
                cancelButtonText: 'Cancel and Edit'
            }).then((result) => {
                if (result.isConfirmed) {
                    sendSurvey();
                } else {
                }
            });
        } else {
            sendSurvey();
        }
    };

    const sendSurvey = () => {
        setIsLoading(true);
        sendSurveyAPI({ employees: items }).then((response) => {
            console.log(response);
            const failedEmails = response.body.failedEmails;
            setItems(prev => {
                let updated = prev.filter(item => failedEmails.includes(item.email));
                updated = updated.map((item, index) => ({
                    ...item,
                    id: index + 1
                }));
                return updated;
            });
            setIsLoading(false);
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);
        });
    };

    const handleGetHistory = () => {

    }

    return (
        <div>
            <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                <div className='gap-3 d-flex'>
                    <Button className='ms-2 mb-3 d-flex align-items-center survey-button' color='primary' onClick={handleImport}><FaFileImport className='me-2' />{'Import File'}</Button>
                    <Button className='ms-2 mb-3 d-flex align-items-center survey-button' color='primary' onClick={handleGetHistory}><FaHistory className='me-2' />{'From History'}</Button>
                    <Button className='ms-2 mb-3 d-flex align-items-center survey-button' color='primary' outline onClick={handleAddOne}><FaPlus className='me-2' />{'Add One'}</Button>
                </div>
                <div className='gap-3 d-flex'>
                    <Button className='ms-2 mb-3 survey-button' color='danger' outline onClick={handleClear}>{'Clear All'}</Button>
                    <Button className='ms-2 mb-3 survey-button' color='success' style={{ minWidth: 100 }} onClick={handleSend}>
                        {isLoading ? <Spinner size="sm" /> : 'Send Survey'}
                    </Button>
                </div>
            </div>
            <input
                type="file"
                ref={inputRef}
                style={{ display: 'none' }}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => {
                    const file = e.target.files[0];
                    readExcel(file);
                    e.target.value = '';
                }}
            />
            <Table items={items} setItems={setItems} setEditRows={setEditRows} editRows={editRows} />
        </div>
    );
}

const tableColumns = [
    {
        name: 'ID',
        selector: row => row['id'],
        sortable: true,
        width: '100px',
        center: false,
    },
    {
        name: 'Email',
        selector: row => row['email'],
        sortable: true,
        center: false,
    },
    {
        name: 'Department',
        selector: row => row['department'],
        sortable: false,
        center: true,
    },
    {
        name: 'Location',
        selector: row => row['location'],
        sortable: false,
        center: true,
    },
    {
        name: 'Action',
        selector: row => row['action'],
        sortable: true,
        center: false,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
];

const Table = ({ items, setItems, setEditRows, editRows }) => {
    const { departments, userInfo } = useContext(UserContext);

    function isSameEmailDomain(email) {
        if (!userInfo.email || typeof userInfo.email !== 'string' || !email || typeof email !== 'string') {
            return false;
        }
        const userInfoEmailDomain = userInfo.email.split('@')[1]?.toLowerCase();
        const emailDomain = email.split('@')[1]?.toLowerCase();
        return userInfoEmailDomain === emailDomain;
    }

    function isDepartmentInList(departmentName) {
        return departments.some(department => department.name === departmentName);
    }

    const handleDelete = (itemId) => {
        setItems(items.filter(item => item.id !== itemId));
    };

    const handleEdit = (row) => {
        setEditRows({
            ...editRows,
            [row.id]: { isEditing: true, tempData: { ...row } }
        });
    };

    const handleSave = (rowId) => {
        const updatedRow = editRows[rowId].tempData;
        setItems(items.map(item => item.id === rowId ? updatedRow : item));
        const newEditRows = { ...editRows };
        delete newEditRows[rowId];
        setEditRows(newEditRows);
    };

    const handleCancel = (rowId) => {
        const newEditRows = { ...editRows };
        delete newEditRows[rowId];
        setEditRows(newEditRows);
    };

    const handleInputChange = (rowId, key, value) => {
        setEditRows({
            ...editRows,
            [rowId]: {
                ...editRows[rowId],
                tempData: { ...editRows[rowId].tempData, [key]: value }
            }
        });
    };

    const renderItem = (item, handleSave, handleCancel, handleEdit, handleDelete, handleInputChange) => {
        console.log(item);
        return {
            id: <H6 key={`id-${item.id}`} attrH6={{ className: 'ms-2 mt-2' }}>{item.id}</H6>,
            email:
                <>
                    {editRows[item.id]?.isEditing ? (
                        <Input
                            className='form-control'
                            type='text'
                            key={`email-${item.id}`}
                            value={editRows[item.id].tempData['email']}
                            onChange={(e) => handleInputChange(item.id, 'email', e.target.value)}
                        />
                    ) : (
                        <H6
                            key={`email-text-${item.id}`}
                            attrH6={{ className: `${((!isSameEmailDomain(item.email))) ? 'text-danger' : 'text-black-50'}` }}>
                            {item.email || ''}
                        </H6>
                    )}
                </>,
            department:
                <>
                    {editRows[item.id]?.isEditing ? (
                        <Input
                            type="select"
                            name="select"
                            key={`department-${item.id}`}
                            className="form-control"
                            value={editRows[item.id].tempData['department']}
                            onChange={(e) => handleInputChange(item.id, 'department', e.target.value)}
                        >
                            <option value={''} key={-1}>{'Select'}</option>
                            {departments.map((items, i) =>
                                <option value={items.name} key={`${i}_${items.name}`}>{items.name}</option>
                            )}
                        </Input>
                    ) : (
                        <H6
                            key={`department-text-${item.id}`}
                            attrH6={{ className: `${((!isDepartmentInList(item.department))) ? 'text-danger' : 'text-black-50'}` }}>
                            {item.department || ''}
                        </H6>
                    )}
                </>,
            location:
                <>
                    {editRows[item.id]?.isEditing ? (
                        <Input
                            type="select"
                            name="select"
                            key={`department-${item.id}`}
                            className="form-control"
                            value={editRows[item.id].tempData['location']}
                            onChange={(e) => {
                                console.log(e.target.value, e.target.value.includes('--'));
                                if (e.target.value.includes('--')) {
                                    let index = CONSTANTS.locatons.indexOf(e.target.value);
                                    handleInputChange(item.id, 'location', CONSTANTS.locatons[index + 1])
                                }
                                else {
                                    handleInputChange(item.id, 'location', e.target.value)
                                }
                            }}
                        >
                            <option value={''} key={-1}>{'Select'}</option>
                            {CONSTANTS.locatons.map((location, i) =>
                                <option key={i} value={location}>{location}</option>
                            )}
                        </Input>
                    ) : (
                        <H6
                            key={`department-text-${item.id}`}
                            attrH6={{ className: `${((!isValidLocation(item.location))) ? 'text-danger' : 'text-black-50'}` }}>
                            {item.location || ''}
                        </H6>
                    )}
                </>,
            action:
                <div style={{ display: 'flex', gap: 10 }}>
                    {editRows[item.id]?.isEditing ? (
                        <>
                            <div className='survey-button me-2' key={`action-check-${item.id}`} onClick={() => handleSave(item.id)}>
                                <FaCheck color={COLORS.success} size={16} />
                            </div>
                            <div className='survey-button me-2' key={`action-close-${item.id}`} onClick={() => handleCancel(item.id)}>
                                <FaBan color={COLORS.danger} size={16} />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='survey-button me-2' key={`action-edit-${item.id}`} onClick={() => handleEdit(item)}>
                                <Edit color={COLORS.primary} size={16} />
                            </div>
                            <div className='survey-button me-2' key={`action-delete-${item.id}`} onClick={() => handleDelete(item.id)}>
                                <Trash2 color={COLORS.danger} size={16} />
                            </div>
                        </>
                    )}
                </div>
        }
    }

    return (
        <div className='email-list'>
            <DataTable
                data={items.map((item, i) =>
                    renderItem(item, handleSave, handleCancel, handleEdit, handleDelete, handleInputChange))}
                columns={tableColumns}
                pagination
                highlightOnHover
                noHeader
            />
        </div>
    );
};

export default ExcelReader;