import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col } from "reactstrap";
import Chart from 'react-apexcharts';
import HeaderCard from './../../../Common/Component/HeaderCard';
import { COLORS, CONSTANTS } from "../../../../Library/theme";
import { useSSR } from "react-i18next";

const apexPieChart = {
    options: {
        chart: {
            width: 450,
            type: 'pie',
            toolbar: {
                show: false
            }
        },
        labels: CONSTANTS.reactions,
        colors: COLORS.chartColors,
        stroke: {
            show: true,
            width: 0.5,
            colors: ['#ffffff'],
        },
        responsive: [{
            breakpoint: 500,
            options: {
                chart: {
                    width: 300
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    },
};

const PieChartClass = ({ data }) => {

    const [series, setSeries] = useState([0, 0, 0, 0, 0]);

    useEffect(() => {
        if (data) {
            setSeries(CONSTANTS.reactions.map((e, index) => data[4 - index] || 0))
        }
    }, [data])

    return (
        <Fragment>
            <Col sm='12' xl='6'>
                <Card>
                    <HeaderCard title={'Percentage of Emoji'} />
                    <CardBody className="apex-chart">
                        <div id='piechart'>
                            <Chart options={apexPieChart.options} series={series} type="pie" width={500} />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    )
}

export default PieChartClass;