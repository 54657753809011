import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Context from './index';
import { getAdminUserAPI, getDepartmentsAPI, getEmptyDepartmentsAPI, getStaffRolesAPI } from '../../Library/apis';
import { MENUITEMS } from '../../Layout/Sidebar/Menu';

const UserProvider = (props) => {

  const [userInfo, setUserInfo] = useState({
    userName: '',
    profileImageUrl: '',
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    phoneNumber: '',
    roles: [],
  });

  const [staffRoles, setStaffRoles] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [emptyDepartments, setEmptyDepartments] = useState([]);

  const [filteredMenuItems, setFilteredMenuItems] = useState(MENUITEMS(['']));

  useEffect(() => {
    if (userInfo.roles && userInfo.roles?.length && userInfo.roles?.includes("View Position") || userInfo.roles?.includes("Staff Position")) {
      updateStaffRoles();
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo.roles && userInfo.roles?.length && userInfo.roles?.includes("View Department") || userInfo.roles?.includes("Add/Update Department")) {
      updateDepartments();
    }
  }, [userInfo]);

  useEffect(() => {
    updateUserInfo();
  }, [userInfo]);

  const updateUserInfo = () => {
    getAdminUserAPI().then(res => {
      if (!_.isEqual(res.body, userInfo)) {
        setUserInfo(res.body);
        res.body?.roles?.length && setFilteredMenuItems(MENUITEMS(res.body.roles));
      }
    }).catch(e => console.log(e));
  }

  const updateStaffRoles = () => {
    getStaffRolesAPI().then(res => {
      if (!_.isEqual(res.roles, staffRoles)) {
        setStaffRoles(res.roles);
      }
    }).catch(e => console.log(e));
  }

  const updateDepartments = () => {
    getDepartmentsAPI().then(res => {
      if (!_.isEqual(res.body, departments)) {
        setDepartments(res.body);
      }
    }).catch(e => console.log(e));

    getEmptyDepartmentsAPI().then(res => {
      const newResponse = res.body.map((item, i) => ({ value: item._id, label: item.name }));
      if (!_.isEqual(newResponse, emptyDepartments)) {
        setEmptyDepartments(newResponse)
      }
    }).catch(e => console.log(e));
  }

  return (
    <Context.Provider
      value={{
        ...props,
        userInfo,
        staffRoles,
        departments,
        filteredMenuItems,
        emptyDepartments,
        setEmptyDepartments,
        setStaffRoles,
        setDepartments,
        setUserInfo,
        updateUserInfo,
        updateDepartments,
        updateStaffRoles
      }}>
      {props.children}
    </Context.Provider>
  );
};

export default UserProvider;
