import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Container, Row, Card, CardBody, Col, Button, Toast } from 'reactstrap';
import Charts from './Chart';
import { Breadcrumbs } from '../../../AbstractElements';

const SurveyByDepartments = () => {

    return (
        <Fragment>
            <Container fluid={true}>
                <Breadcrumbs mainTitle='Survey By Departments' />
                <Charts />
            </Container >
        </Fragment >
    );
};

export default SurveyByDepartments;
