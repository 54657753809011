import React, { useEffect, useState } from "react";
import { Row, Col, Modal, ModalHeader, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { toast } from "react-toastify";
import { deleteStaffAPI, disableStaffAPI, updateStaffAPI } from "../../../../Library/apis";

const EditStaffsModal = ({ modal, toggle, item, updateStaffList }) => {

    const [formValues, setFormValues] = useState({
        staffId: '',
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        positionId: "",
        profileImageUrl: ""
    });

    useEffect(() => {
        if (item) {
            setFormValues({
                staffId: item._id || '',
                firstName: item?.userInfo.firstName || "",
                lastName: item?.userInfo.lastName || "",
                phoneNumber: item.phoneNumber || "",
                profileImageUrl: item.profileImageUrl || "",
                email: item.email || "",
                positionId: item.positionId || "",
            })
        }
    }, [item])

    const handleUpdate = () => {
        updateStaffAPI(formValues).then((res) => {
            toast.success(res.message, { autoClose: 1000 });
            toggle();
            updateStaffList();
        }).catch((error) => {
            const message = error?.response?.data?.message || 'Update Profile Error'
            toast.error(message, { autoClose: 1000 });
        });
    }

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormValues(({ ...formValues, [name]: value }));
    };

    const handleActive = (e) => {
        disableStaffAPI({ staffId: formValues.staffId }).then(res => {
            toast.success(res.message, { autoClose: 1000 });
            toggle();
            updateStaffList();
        }).catch(e => toast.error('Staff Disable/Enable error'));
    };

    const handleDelete = (e) => {
        deleteStaffAPI({ staffId: formValues.staffId }).then(res => {
            toast.success(res.message, { autoClose: 1000 });
            toggle();
            updateStaffList();
        }).catch(e => toast.error('Staff Delete error'));
    };

    return (
        <Modal isOpen={modal} toggle={toggle} centered className="font-outfit">
            <ModalHeader toggle={toggle} >{'Edit Staff'}</ModalHeader>
            <div className='d-flex flex-direction-column' style={{ flexDirection: 'column', gap: 20, marginLeft: 30, marginRight: 30, marginBottom: 30 }}>
                <Form>
                    <Row>
                        <Col md="6">
                            <FormGroup className="mb-3">
                                <Label className="form-label">{'First Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="First Name"
                                    name="firstName"
                                    required
                                    value={formValues.firstName || ""}
                                    onChange={handleChange}
                                    disabled={!item?.active}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="12" md="6">
                            <FormGroup> <Label className="form-label">{'Sure Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    value={formValues.lastName || ""}
                                    name="lastName"
                                    onChange={handleChange}
                                    placeholder="Sure Name"
                                    disabled={!item?.active}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="12" md="6">
                            <FormGroup><Label className="form-label">{'Email Address'}</Label>
                                <Input
                                    className="form-control"
                                    type="email"
                                    value={formValues.email || ""}
                                    onChange={handleChange}
                                    name="email"
                                    placeholder="Email Address"
                                    disabled={!item?.active}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="12" md="6">
                            <FormGroup><Label className="form-label">{'Phone Number'}</Label>
                                <Input
                                    className="form-control"
                                    type="phone"
                                    value={formValues.phoneNumber || ""}
                                    onChange={handleChange}
                                    name="phoneNumber"
                                    placeholder="Phone Number"
                                    disabled={!item?.active}
                                    required />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                {!item?.superAdmin &&
                    <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                        <div>
                            <Button color="warning" outline onClick={handleActive} className="me-2">
                                {item?.active ? 'Disable' : 'Enable'}
                            </Button>
                            <Button color="secondary" outline onClick={handleDelete} className="me-2">
                                {'Delete'}
                            </Button>
                        </div>
                        <div>
                            <Button color="success" onClick={handleUpdate} className="me-2">
                                {'Update'}
                            </Button>
                        </div>
                    </div>
                }
            </div>
        </Modal >
    );
};

export default EditStaffsModal;
